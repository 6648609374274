
import {map} from 'rxjs/operators';
import { Component, Input, OnChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import {HttpParams} from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';

import { UtilityService } from '../../services/utility.service';
import { ApiService } from './../../services/api.service';

@Component({
	selector: 'p-person-profile-common',
	templateUrl: './person-profile.component.html',
})
export class PersonProfileCommonComponent implements OnChanges {

	@Input() personId;
	@Input() asOfDate = new Date(Date.now()).toString();
	@Input() expanded : Boolean = true;
	profile:any = {};

	constructor(private apiService: ApiService,
		private fb: FormBuilder,
		private sanitizer: DomSanitizer,
		public utilityService: UtilityService) { }

	ngOnChanges() {
		if (this.personId) {
			let params: HttpParams = new HttpParams();
			params = params.set('asOfDate', this.utilityService.formatDate(this.asOfDate));
			this.apiService.get('/persons/profile/' + this.personId, params).pipe(
				map(data => data.employeeProfile))
				.subscribe(data => {
					if (data) {
						this.profile = data;
					}
				});
		}
	}

}
