
import {map} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';

import { BPMUtils } from 'app/shared/bpmutils';
import { UtilityService, ApiService } from 'app/shared/services';
import { DocumentActionFormComponent } from 'app/shared/bpmutils/document-action-form/document-action-form';
import { Router, ActivatedRoute } from "@angular/router";
import { EventService } from "app/shared";

@Injectable()
export class BPMUtilsUserActions {

    constructor(
        private bpmUtils: BPMUtils,
        private dialog: MatDialog,
        private apiService: ApiService,
        private utilityService: UtilityService,
        private router: Router
        , private eventService: EventService
        , private route: ActivatedRoute
    ) {
    }

    doDocAction(processInstId, url = ''): Promise<any> {
        return new Promise((resolve, reject) => {
            this.bpmUtils.getBPMActions(processInstId, BPMUtils.BPMUser)
                .then((task) => {
                    if (task.action == "CLAIM") {
                        this.isTaskClaimeAllowed(processInstId).then(access => {
                            if (access) {
                                const dialogRef = this.dialog.open(DocumentActionFormComponent);
                                dialogRef.componentInstance.processInstanceId = processInstId;
                                dialogRef.componentInstance.isClaim = true;
                                dialogRef.afterClosed().subscribe(result => {
                                    if (result == 'claimed') {
                                        this.doDocAction(processInstId, url).then((data) => {
                                            resolve(true);
                                        }, reject);
                                    } else {
                                        reject();
                                    }
                                });
                            } else {
                                this.utilityService.showAlerts('You have nothing to do.');
                                reject();
                            }
                        }, reject);
                    }
                    else if (task.action == "SUBMIT") {
                        const dialogRef = this.dialog.open(DocumentActionFormComponent);
                        dialogRef.componentInstance.processInstanceId = processInstId;
                        dialogRef.componentInstance.task = task;
                        dialogRef.afterClosed().subscribe(result => {
                            if (result) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        });
                    } else {
                        this.utilityService.showAlerts('Document action failed. BPM-EXC-104.');
                        reject();
                    }
                });
        });
    }

    canChangeAssignee(processInstId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.apiService.get('/bpm/' + processInstId + '/changeAssignee')
                .subscribe(data => {
                    if (data.processInstanceId == processInstId) {
                        if (data.status == 'S' && data.changeAssignee == 'Y') {
                            resolve(true);
                        } else {
                            this.utilityService.showAlerts('Insufficiet privilages to change assignee.');
                            resolve(false);
                        }
                    }
                }, err => {
                    this.utilityService.showAlerts('Failed to change assignee.');
                    resolve(false);
                }
                );
        });
    }

    changeAssignee(processInstId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.canChangeAssignee(processInstId).then((canChange) => {
                if (canChange) {
                    this.bpmUtils.getTaskIdFromInstance(processInstId)
                        .subscribe((task) => {
                            if (task && task.taskId) {
                                resolve(true);
                                this.bpmUtils.changeAssignee(processInstId, task.taskId).then(() => { });
                            } else {
                                this.utilityService.showAlerts('Failed to change assignee.');
                                reject();
                            }
                        });
                } else {
                    resolve(false);
                }
            });
        });
    }

    startProcessInstance(bpmData): Promise<string> {
        return this.bpmUtils.startProcessInstance(bpmData);
    }

    isTaskClaimeAllowed(instanceId): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.bpmUtils.isTaskClaimAllowed(instanceId)
                .subscribe(res => {
                    if (res) {
                        if (res.access == 1) {
                            resolve(true);
                        } else {
                            this.utilityService.showAlerts(res.message)
                            resolve(false);
                        }
                    } else {
                        resolve(false);
                    }
                })
        });
    }

    claimTask(instanceId) {
        this.isTaskClaimeAllowed(instanceId).then(access => {
            if (access) {
                this.bpmUtils.getTaskIdFromInstance(instanceId)
                    .subscribe((task) => {
                        if (task && task.taskId) {
                            this.bpmUtils.claimProcessTask(task.taskId).then((status) => {
                                if (status == 200) {
                                    this.utilityService.showAlerts("Task claimed.");
                                } else {
                                    this.utilityService.showAlerts("Task claim failed.");
                                }
                            });
                        } else {
                            this.utilityService.showAlerts('Failed to change assignee.');
                        }
                    });

            }
        })
    }

    updateProcessInstanceId(schema, object, primaryColumn, instanceColumn, instanceId, primaryId): Observable<any> {
        return this.apiService.put('/common/update-process-instance/' + schema + '/' + object + '/' + primaryColumn + '/' + instanceColumn + '/' + instanceId + '/' + primaryId).pipe(
            map(data => data));
    }
    getWorkflowKey(docTypeId): Observable<any> {
        return this.apiService.get('/common/workflowkey/' + docTypeId).pipe(
            map(data => data))
    }
}