
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Headers, Response, URLSearchParams } from '@angular/http';
import { UserService } from 'app/shared/services/user.service';



import { JwtService } from './jwt.service';

@Injectable()
export class FleetApiService {

  fleetManagementAuthentication;
  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
    private userService: UserService,

  ) {
    this.userService.currentUser.subscribe(user => {
      this.fleetManagementAuthentication = user.fleetManagementAuthentication;
    });
  }

  private setHeaders(): HttpHeaders {
    let headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.fleetManagementAuthentication}`;
    }
    return new HttpHeaders(headersConfig);
  }

  private getMultipartHeaders(): HttpHeaders {
    let headersConfig = {
    };
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.fleetManagementAuthentication}`;
    }
    return new HttpHeaders(headersConfig);
  }

  private formatErrors(error: any) {
    return observableThrowError(error);
  }

  get(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
    return this.http.get(`${environment.flm_url}${path}`, { headers: this.setHeaders(), params })
      .catch(this.formatErrors)
      .map((res: any) => {
        let json = JSON.parse(JSON.stringify(res));
        json.token = token;
        return json;
      });
  }

  getHTTP(path: string, params: HttpParams = new HttpParams(), token = Math.random()): Observable<any> {
    return this.http.get(`${environment.flm_url}${path}`, { headers: this.setHeaders(), params })
      .catch((error: any) => observableThrowError({ status: error.status, data: error }))
      .map((res: any) => {
        let data: any = '';
        try {
          data = JSON.parse(JSON.stringify(res));
          data.token = token;
        } catch (e) {
          data = res.text();
        }
        return { status: res.status, data: data }
      });
  }



  getImage(path: string, params: HttpParams = new HttpParams()): Observable<any> {
    const headersConfig = {
      'Content-Type': 'image/jpeg',
      'Accept': 'image/jpeg',
    };
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
    }
    return this.http.get(`${environment.flm_url}${path}`, { headers: this.setHeaders(), params })
      .catch(this.formatErrors)
      .map((res: any) => res);
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(
      `${environment.flm_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders() }
    )
      .catch(this.formatErrors)
      .map((res: any) => res);
  }

  post(path: string, body: Object = {}): Observable<any> {
    return this.http.post(
      `${environment.flm_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders() }
    )
      .catch(this.formatErrors)
      .map((res: any) => res);
  }

  postMultipart(path: string, body): Observable<any> {
    return this.http.post(
      `${environment.flm_url}${path}`, body, { headers: this.getMultipartHeaders() }

    )
      // .catch(this.formatErrors)
      .map((res: any) => res);
  }

  delete(path): Observable<any> {
    return this.http.delete(
      `${environment.flm_url}${path}`,
      { headers: this.setHeaders() }
    )
      .catch(this.formatErrors)
      .map((res: any) => res);
  }
}
