import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'trendz-drive-modal',
	templateUrl: './drive-modal.component.html',
	styleUrls: ['./drive-modal.component.scss']
})
export class TrendzDriveModalComponent implements OnInit, OnDestroy {

	root = '';
	basePath = "";
	privileges = "";
	extraPaths = "";

	constructor(
		private dialogRef: MatDialogRef<TrendzDriveModalComponent>
	) {
	}

	ngOnInit(): void {
	}

	ngOnDestroy() {

	}

	closeDrive() {
		this.dialogRef.close();
	}

}
