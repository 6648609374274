import { Directive, OnInit, ElementRef, Input, AfterViewInit } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[trendzAutoFocus]'
})
export class FocusDirectiveDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) { };

  ngAfterViewInit() {
    setTimeout(() => {
      this.elementRef.nativeElement.focus();
    })
  }
}
