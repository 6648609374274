
import {takeUntil, map} from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Router } from '@angular/router';
import { ApiService } from 'app/shared/services/api.service';
import { UtilityService } from 'app/shared/services/utility.service';
import { UserService } from 'app/shared/services/user.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { BPMUtils } from 'app/shared/bpmutils';


@Component({
  selector: 'app-employee-user-link',
  templateUrl: './employee-user-link.component.html',
  styleUrls: ['./employee-user-link.component.css']
})
export class EmployeeUserLinkComponent implements OnInit {
  personnelNum: any;
  private ngUnsubscribe: Subject<any> = new Subject<any>();
  formGroup: FormGroup;
  clicked = false;
  personnelNumAutoComplete: any;
  constructor(private fb: FormBuilder,
    public dialogRef: MatDialogRef<EmployeeUserLinkComponent>,
    private router: Router,
    private apiService: ApiService,
    public utilityService: UtilityService,
    private bpmUtils: BPMUtils,
    private userService: UserService) {
    this.formGroup = this.fb.group({
      personnelNum: ['', Validators.pattern(/^[A-Z0-9_]*$/)],
    });
    // this.formGroup.controls.personnelNum.valueChanges.takeUntil(this.ngUnsubscribe).subscribe(value => {
    //   //console.log(value); 
    // });
  }

  ngOnInit() {
  }

  saveChanges() {
    this.personnelNum = this.formGroup.controls.personnelNum.value;
    this.checkPersonnelNumber();
  }

  discardChanges() {
    this.dialogRef.close();
  }

  checkPersonnelNumber() {
    this.apiService.get("/persons/personnel-number/validate-div-personType?personnelNumber=" + this.formGroup.controls.personnelNum.value + '&personType=E').pipe(
      map(data => data),takeUntil(this.ngUnsubscribe),)
      .subscribe(data => {
        if (data.ret.count > 0) {
          this.initiateRequest();
        }
        else {
          this.utilityService.showAlerts("Personnel# doesn't exist");
        }
      });
  }

  initiateRequest(): Promise<string> {
    this.clicked = true;
    return new Promise((resolve, reject) => {
      this.bpmUtils.startProcessInstance(this.generateDocActionVariable())
        .then(bpmRes => {
          if (bpmRes) {
            this.utilityService.showAlerts('Employee Linking Requested');
            this.dialogRef.close(true);
            this.clicked = false;
            resolve(bpmRes);
          } else {
            this.utilityService.showAlerts('Failed to request employee link.');
            this.clicked = false;
            resolve('');
          }
        });
      resolve();
    });
  }

  generateDocActionVariable(): any {
    const variables = [];
    variables.push({ name: 'trendzAppUser', value: this.userService.getCurrentUser().userName });
    variables.push({ name: 'personnelNum', value: this.personnelNum });
    return this.utilityService.getBpmJSON('CFAIJOHcmEmployeelinkWorkflow', variables);
  }
}