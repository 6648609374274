import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { User, UserService, UtilityService } from 'app/shared';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'home-page',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, OnDestroy {
  timeout;
  refresh: any;

  currentUser: User;
  userSubs: Subscription;
  iframeUrl: any;
  constructor(
    private userService: UserService,
    private utilityService: UtilityService,
    private route: ActivatedRoute
  ) {
    if (this.userService.iframeData) {
      let iframeData = this.userService.iframeData;
      this.iframeUrl = atob(iframeData.iframeUrl);
      this.refresh = iframeData.refresh
      if (this.refresh) {
        this.setRefreshCycle();
      }
    }
    else {
      this.utilityService.fetchLoginIframeUrl()
        .then(iframeData => {
          if (iframeData && iframeData.status == 'S' && iframeData.iframeUrl != '') {
            this.iframeUrl = atob(iframeData.iframeUrl);
            this.refresh = iframeData.refresh
            if (this.refresh) {
              this.setRefreshCycle();
            }
          } else {
            this.utilityService.showAlerts(iframeData && iframeData.msg ? iframeData.msg : "No url configured for this user.")
          }
        })
        .catch(err => {
          this.utilityService.showAlerts("No url configured for this user.")
        });
    }
  }

  ngOnInit() {
    this.userSubs = this.userService.currentUser.subscribe(
      (userData) => {
        this.currentUser = userData;
      }
    );
  }

  ngOnDestroy() {
    if (this.userSubs)
      this.userSubs.unsubscribe();
    if (this.timeout) {
      clearInterval(this.timeout)
    }
  }

  showAlert() {
    var tmp = document.implementation.createHTMLDocument("New").body;
    let html = "this is a <a href='#'>test></a> <b>:)</b>";
    tmp.innerHTML = html;
    //  return tmp.textContent || tmp.innerText || "";
    this.utilityService.showAlerts(tmp.textContent || tmp.innerText || "");
  }

  setRefreshCycle() {
    let cls = this;
    this.timeout = setInterval(function () {
      cls.utilityService.fetchLoginIframeUrl()
        .then(iframeData => {
          if (iframeData && iframeData.status == 'S' && iframeData.iframeUrl != '') {
            cls.iframeUrl = atob(iframeData.iframeUrl);
            if (iframeData.refresh) {
              cls.refresh = this.refresh
            } else {
              cls.refresh = null;
              if (this.timeout) {
                clearInterval(this.timeout)
              }
            }
          } else {
            cls.iframeUrl = null;
            cls.refresh = null;
            if (this.timeout) {
              clearInterval(this.timeout)
            }
          }
        })
        .catch(err => {
          this.utilityService.showAlerts("No url configured for this user.")
          if (this.timeout) {
            clearInterval(this.timeout)
          }
        });
    }, cls.refresh * 1000);
  }
}
