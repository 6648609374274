
import {map, distinctUntilChanged} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Router, RouterStateSnapshot } from "@angular/router";
import { Observable ,  BehaviorSubject ,  ReplaySubject ,  Subject } from "rxjs";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";

import { ApiService } from "./api.service";
import { JwtService } from "./jwt.service";
import { User } from "../models";

@Injectable()
export class UserService {
  public currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject
    .asObservable().pipe(
    distinctUntilChanged());
  public isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public redirectUrl;
  public iframeData;
  public authenticated = false;
  private emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  division : string;

  private trendzBrodcastChannelSubject = new Subject<any>();
  trendzBrodcastChannelChangeEmitted$ = this.trendzBrodcastChannelSubject.asObservable();
  trendzBroadcastChannel: BroadcastChannel;
  private tabId = sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.round(Math.random()*10000);
  localToken = localStorage.getItem('jwtToken');
  constructor(
    private apiService: ApiService,
    private http: HttpClient,
    private router: Router,
    private jwtService: JwtService
  ) {
    this.trendzBroadcastChannel = new BroadcastChannel('trendz-app-broadcast-channel');
    this.emitBrodcastChannelChange();
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.jwtService.getToken()) {

      this.apiService
        .get("/user")
        .subscribe(data => this.setAuth(data.user), err => {});
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(user: User) {
    if (user.resetToken) {
      this.authenticated = false;
      this.jwtService.saveResetToken(user.resetToken);
    } else {
      // Save JWT sent from server in localstorage
      this.localToken = user.token;
      this.jwtService.saveToken(user.token);
      // Set current user data into observable
      this.currentUserSubject.next(user);
      // Set isAuthenticated to true
      this.isAuthenticatedSubject.next(true);
      this.authenticated = true;
    }
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.jwtService.destroyToken();
    // Set current user to an empty object
    this.currentUserSubject.next(new User());
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    this.authenticated = false;
    this.router.navigateByUrl("/login", {
      queryParams: { returnUrl: this.redirectUrl }
    });
  }

  attemptAuthentication(credentials): Observable<User> {
    return this.apiService.post("/user/login", credentials).pipe(map(data => {
      this.setAuth(data.user);
      return data;
    }));
  }
  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService.put("/user", { user }).pipe(map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }

  createOrgPref(tnzOrgPref, userId): Observable<any> {
    return this.apiService
      .post("/trendz/settings/" + userId, { tnzUserOrgPreference: tnzOrgPref }).pipe(
      map(data => data));
  }

  updateOrgPref(tnzOrgPref, userId): Observable<any> {
    return this.apiService
      .put("/trendz/settings/" + userId, { tnzUserOrgPreference: tnzOrgPref }).pipe(
      map(data => data));
  }

  emitChange(change: any) {
    if (change && change.divisionChange && change.divisionChange.label) {
      this.division = change.divisionChange.value;  
    }
    this.emitChangeSource.next(change);
  }

  // emitBrodcastChannelChange(change: any) {
  //   this.trendzBrodcastChannelSubject.next(change);
  // }
  emitBrodcastChannelChange() {
    this.trendzBroadcastChannel.onmessage = (e) => {
      this.trendzBrodcastChannelSubject.next(e.data);
  };
  }

  broadcastMessage(message: any) {
    this.trendzBroadcastChannel.postMessage(message);
  }

  getTabId(){
  return this.tabId;
  }


}
