import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[trendzQuickSave]'
})



export class QuickSaveDirective {
    @Output() save: EventEmitter<any> = new EventEmitter<any>();
      
    constructor() {
    }

    @HostListener('document:keypress', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) { 
        if ((event.code == "Enter" && event.which === 10 && event.ctrlKey)) {
            this.save.emit({save:true});
        }
    }

}