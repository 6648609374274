import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'currency' })
export class CurrencyPipe implements PipeTransform {
    transform(value: number, currency: string, minorCode: string): any {
        let formatted: any;

        if (!currency) {
            switch (minorCode) {
                case '1':
                    formatted = (value.toFixed(1));
                    break;

                case '2':
                    formatted = (value.toFixed(2));
                    break;

                case '3':
                    formatted = (value.toFixed(3));
                    break;

                case '4':
                    formatted = (value.toFixed(4));
                    break;

                case '5':
                    formatted = (value.toFixed(5));
                    break;

                case '6':
                    formatted = (value.toFixed(6));
                    break;

                case '7':
                    formatted = (value.toFixed(7));
                    break;

                case '8':
                    formatted = (value.toFixed(8));
                    break;

                case '9':
                    formatted = (value.toFixed(9));
                    break;

                case '10':
                    formatted = (value.toFixed(10));
                    break;

            }
        } else {

            switch (minorCode) {
                case '1':
                    formatted = currency + ' ' + (value.toFixed(1));
                    break;

                case '2':
                    formatted = currency + ' ' + (value.toFixed(2));
                    break;

                case '3':
                    formatted = currency + ' ' + (value.toFixed(3));
                    break;

                case '4':
                    formatted = currency + ' ' + (value.toFixed(4));
                    break;

                case '5':
                    formatted = currency + ' ' + (value.toFixed(5));
                    break;

                case '6':
                    formatted = currency + ' ' + (value.toFixed(6));
                    break;

                case '7':
                    formatted = currency + ' ' + (value.toFixed(7));
                    break;

                case '8':
                    formatted = currency + ' ' + (value.toFixed(8));
                    break;

                case '9':
                    formatted = currency + ' ' + (value.toFixed(9));
                    break;

                case '10':
                    formatted = currency + ' ' + (value.toFixed(10));
                    break;

            }

        }
        return formatted;
    }
}

