
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";



import { JwtService } from './jwt.service';
import { UtilityService } from 'app/shared';


@Injectable()
export class AuthService {

  public emitChangeSource = new Subject<any>();
  changeEmitted$ = this.emitChangeSource.asObservable();
  url: any;

  constructor(
    private http: HttpClient,
    private jwtService: JwtService,
  ) { }

  private setHeaders(config?: Object): HttpHeaders {
    let headersConfig = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    if (config && config['token'] === 'resetToken') {
      headersConfig['Authorization'] = `${this.jwtService.getResetToken()}`;
    } else if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }
  private getMultipartHeaders(): HttpHeaders {
    let headersConfig = {
    };
    if (this.jwtService.getToken()) {
      headersConfig['Authorization'] = `${this.jwtService.getToken()}`;
    }
    return new HttpHeaders(headersConfig);
  }

  put(path: string, body: Object = {}, config: Object = {}): Observable<any> {
    this.url = {
      url: path,
      method: 'put'
    }
    return this.http.put(
      `${environment.api_url}${path}`,
      JSON.stringify(body),
      { headers: this.setHeaders(config) }
    )
      .catch(this.formatErrors.bind(this))
      .map((res: any) => res);
  }

  private formatErrors(error: any) {
    if (this.url.url != '/user/login')
      // this.emitChangeSource.next({ err: error, url: this.url });
      return observableThrowError(error);
  }


}


