import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material';
import * as $ from 'jquery';

import { UtilityService } from 'app/shared/services';
import { TrendzUsersService } from 'app/administration/trendz/trendz-users/_services';
import { ConfirmPopupComponent } from 'app/shared/component';
import { TrendzDriveService } from 'app/trendzdrive/_services';
import { DriveElementFilterPipe } from './utils';
import { TrendzDriveInputModalComponent } from 'app/trendzdrive';
import { TrendzFilePreviewComponent } from 'app/shared/component/file-preview/file-preview.component';
import { EmbedService } from 'app/embed/_services/embed.service';

@Component({
	selector: 'trendz-drive',
	templateUrl: './trendz-drive.component.html',
	styleUrls: ['./trendz-drive.component.scss']
})
export class TrendzDriveComponent implements OnInit, OnChanges, OnDestroy {

	@Input() cmisRoot = '';
	@Input() basePath = '';
	@Input() privileges;
	@Input() appKey = "";
	@Input() extraPaths = "";

	private componentInstance = Math.random();
	initialized = -1;
	private currentNode: any;
	breadCrumbs = [];
	elements = [];
	token = Math.random();
	apiUrl = '/trendz-drive/';
	driveScope = "main";

	viewMode = 'tile';
	private fileTypeIcons = {
		jpg: 'jpg',
		jpeg: 'jpg',
		png: 'png',
		svg: 'svg',
		pdf: 'pdf',
		xlsx: 'xls',
		xls: 'xls',
		doc: 'doc',
		docx: 'doc',
		csv: 'csv',
		ppt: 'ppt',
		pptx: 'ppt',
		rtf: 'rtf',
		txt: 'txt',
		zip: 'zip',
		rar: 'zip',
		avi: 'avi',
		mp3: 'mp3',
		mp4: 'mp4',
		jar: 'jar',
		file: 'file'
	}

	@ViewChild('trendzDrive') trendzDrive: ElementRef;
	@ViewChild('fileUploadHelper') fileInput: ElementRef;
	@ViewChild('contextMenu') contextMenu: ElementRef;

	counts: any = {};
	uploadQueue: any[] = [];
	uploadQueueList: any[] = [];
	uploadListCollapse = false;
	private uploadingFile: any;
	contextMenuScope = '';
	contextMenuStyle: any = {};
	private contextMenuActive = false;
	copiedElements = [];
	private copyFrom;
	copyMode;
	private onKeyActive = true;
	curSort = { sort: 'name', order: 'asc' };

	warning: string;

	private _privileges = {};
	private _extraPaths = [];

	private fetching = false;

	constructor(
		private service: TrendzDriveService,
		public utilityService: UtilityService,
		private userService: TrendzUsersService,
		private embedService: EmbedService,
		private dialog: MatDialog
	) { }

	ngOnInit(): void {
		this.service.currentComponentInstance = this.componentInstance;
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.initialized = -1;
		this.elements = [];
		this.counts = {};
		this.breadCrumbs = [];
		this._extraPaths = this.extraPaths.split('|');
		this._privileges = this.privileges;
		this.token = Math.random();
		if (this.basePath) {
			if (!changes.privileges && changes.appKey)
				this.privileges = null;
			if (!this.cmisRoot)
				this.cmisRoot = 'TrendzV4';
			this.basePath = btoa(this.cmisRoot + "/" + this.basePath);
			if (!this.privileges) {
				if (this.appKey) {
					this.userService.checkPrevilage(this.appKey)
						.subscribe(data => {
							this.privileges = data;
							this.launchDrive();
						},
							() => {
								this.privileges = { view: 0, create: 0, edit: 0, delete: 0 };
							});
				}
				else {
					this.privileges = { view: 0, create: 0, edit: 0, delete: 0 };
					this.launchDrive();
				}
			}
			else {
				this.launchDrive();
			}
		}
	}

	ngOnDestroy() {
		this.elements = [];
		this.counts = {};
	}

	launchDrive(): Promise<any> {
		return new Promise((resolve) => {
			this._privileges = this.privileges;
			if (this.privileges.view != 4) {
				this.initialized = 0;
				resolve();
			}
			if (!this.fetching) {
				this.fetching = true;
				this.currentNode = this.service.getFolder(this.basePath).then(data => {
					this.setCurrentFolder(data.folder);
					this.fetching = false;
					this.initialized = 1;
					if (this.privileges.create != 4 || this.privileges.edit != 4) {
						this.warning = "You don't have permission to";
						if (this.privileges.create != 4) {
							this.warning += " create";
						}
						if (this.privileges.create != 4 && this.privileges.edit != 4) {
							this.warning += " and";
						}
						if (this.privileges.edit != 4) {
							this.warning += " edit";
						}
						this.warning += " contents in this folder.";
					}
					resolve();
				}, () => {
					this.fetching = false;
					this.initialized = 0;
					resolve();
				});
			}
		});
	}

	toogleDriveScope() {
		this.elements = [];
		this.counts = {};
		this.breadCrumbs = [];
		if (this.driveScope != "main") {
			this.privileges = this._privileges;
			this.launchDrive().then(() => {
				this.driveScope = "main";
				this.warning = "";
			});
		}
		else {
			this.privileges = { view: 4, create: 0, edit: 0, delete: 0 };
			this.loadExtraPaths().then(() => {
				this.driveScope = "extra";
				this.warning = "You can only view the contents.";
			});;
		}
	}

	loadExtraPaths(): Promise<any> {
		return new Promise((resolve) => {
			let extraFolder: any = {};
			extraFolder.name = "Linked Documents";
			extraFolder.key = "EXTRAPATHS";
			extraFolder.children = [];
			let completed = 0;
			if (!this.fetching) {
				this.fetching = true;
				this._extraPaths.forEach(path => {
					this.service.getFolder(btoa(path)).then(data => {
						completed++;
						extraFolder.children.push(...data.folder.children);
						if (completed == this._extraPaths.length) {
							this.fetching = false;
							this.initialized = 1;
							this.setCurrentFolder(extraFolder);
							resolve();
						}
					}, () => {
						completed++;
						if (completed == this._extraPaths.length) {
							this.fetching = false;
							this.initialized = 1;
							this.setCurrentFolder(extraFolder);
							resolve();
						}
					});
				});
			}
		});
	}

	initDrive() {
		if (this.privileges.create != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to use drive');
			return;
		}
		this.initialized = -1;
		this.currentNode = this.service.initDrive(this.basePath).then(data => {
			this.setCurrentFolder(data.folder);
			this.initialized = 1;
		}, () => {
			this.initialized = 0;
			this.utilityService.showAlerts('Failed to load drive');
		});
	}

	setCurrentFolder(folder) {
		if (this.privileges.view != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to view contents');
			return;
		}
		this.elements = [];
		this.counts = {};
		this.currentNode = folder;
		if (this.currentNode.children) {
			for (const obj of this.currentNode.children) {
				this.elements.push(obj);
			}
			this.setCounts();
			this.sortElements();
		}
		let index = -1;
		for (let i = 0; i < this.breadCrumbs.length; i++) {
			if (this.breadCrumbs[i].key == folder.key) {
				index = i;
				break;
			}
		}
		if (index > -1) {
			this.breadCrumbs.splice(index + 1, this.breadCrumbs.length - index);
		} else {
			this.breadCrumbs.push({ title: (this.currentNode.key == this.basePath ? 'Home' : this.currentNode.name), key: this.currentNode.key });
		}
	}

	fetchFolder(key) {
		if (this.privileges.view != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to access contents');
			return;
		}

		if (key == 'EXTRAPATHS') {
			this.loadExtraPaths();
		}
		else {
			if (!this.fetching) {
				this.fetching = true;
				this.service.getFolder(key).then(data => {
					this.setCurrentFolder(data.folder);
					this.fetching = false;
				}, () => {
					this.utilityService.showAlerts('Failed to load folder');
					this.fetching = false;
				});
			}
		}
	}

	switchViewMode() {
		if (this.viewMode == 'list') {
			this.viewMode = 'tile';
		} else {
			this.viewMode = 'list';
		}
	}

	getfileIcon(type) {
		if (this.fileTypeIcons[type]) {
			return this.fileTypeIcons[type] + '.png';
		}
		return 'file.png';
	}

	setCounts(skipMode = 0) {
		this.counts.selected = 0;
		this.counts.selectedFolders = 0;
		this.counts.selectedDocuments = 0;
		if (skipMode == 0) {
			this.counts.folders = 0;
			this.counts.documents = 0;
		}
		if (!this.counts.folders)
			this.counts.folders = 0;
		if (!this.counts.documents)
			this.counts.documents = 0;
		if (skipMode != 2) {
			for (let elem of this.elements) {
				if (elem.selected) {
					this.counts.selected++;
					if (elem.baseType == 'folder') {
						this.counts.selectedFolders++;
					} else if (elem.baseType == 'document') {
						this.counts.selectedDocuments++;
					}
				}
				if (skipMode != 2) {
					if (elem.baseType == 'folder') {
						this.counts.folders++;
					}
					else if (elem.baseType == 'document') {
						this.counts.documents++;
					}
				}
			}
		}
	}

	sortElements(sort = 'name', order = 'asc') {
		this.elements.sort((a, b) => {
			let r = 0;
			if (a.baseType == 'folder' && b.baseType == 'document') {
				r = -1;
			} else if (a.baseType == 'document' && b.baseType == 'folder') {
				r = 1;
			} else {
				switch (sort) {
					case 'name':
						r = a[sort].toLowerCase().localeCompare(b[sort].toLowerCase());
						break;
					case 'size':
						r = a[sort] < b[sort] ? -1 : a[sort] > b[sort] ? 1 : 0;
						break;
					case 'created':
					case 'modified':
						let d1 = this.utilityService.formatToCalDate(a[sort]);
						let d2 = this.utilityService.formatToCalDate(b[sort]);
						r = d1 < d2 ? -1 : d1 > d2 ? 1 : 0;
						break;
					default:
						r = 0;
						break;
				}
			}
			if (order == 'desc') {
				r = 0 - r;
			}
			return r;
		});
		this.curSort = { sort: sort, order: order };
		this.token = Math.random();
	}

	elementClicked(e, key) {
		this.selectElement(key, e.ctrlKey);
	}

	selectElement(key, ctrl = false) {
		for (let elem of this.elements) {
			if (elem.key == key) {
				elem.selected = true;
				elem.focused = true;
			} else {
				elem.focused = false;
				if (!ctrl && elem.selected) {
					elem.selected = false;
				}
			}
		}
		this.setCounts(1);
	}

	selectAll() {
		this.hideContextMenu();
		for (const elem of this.elements) {
			elem.selected = true;
		}
		this.setCounts(1);
	}

	clearSelections() {
		for (let elem of this.elements) {
			elem.selected = false;
			elem.focused = false;
		}
		this.setCounts(2);
	}

	createFolder() {
		this.hideContextMenu();
		if (this.privileges.create != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to create folder');
			return;
		}
		const dialogRef = this.dialog.open(TrendzDriveInputModalComponent);
		this.onKeyActive = false;
		dialogRef.componentInstance.dialogTitle = 'Create Folder';
		dialogRef.componentInstance.placeHolder = 'Untitled Folder';
		dialogRef.afterClosed().subscribe(title => {
			this.onKeyActive = true;
			if (title) {
				this.service.createFolder(title, this.currentNode.key).then(data => {
					if (data.folder) {
						this.elements.push(data.folder);
						this.token = Math.random();
						this.setCounts();
					}
					this.utilityService.showAlerts('Folder created');
				}, () => {
					this.utilityService.showAlerts('Failed to create folder');
				});
			}
		});
	}

	initUpload() {
		this.hideContextMenu();
		if (this.privileges.create != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to upload contents');
			return;
		}
		this.fileInput.nativeElement.click();
	}

	uploadFiles() {
		const inputElem: HTMLInputElement = this.fileInput.nativeElement;
		const fileCount: number = inputElem.files.length;
		const key = this.currentNode.key;
		const path = atob(key) + '/';
		const regx = /(?:\.([^.]+))?$/;
		for (let i = 0; i < fileCount; i++) {
			const file = inputElem.files.item(i);
			const fileObj = { parent: key, file: file }
			this.uploadQueue.push(fileObj);
			this.uploadQueueList.unshift({ key: btoa(path + file.name), file: fileObj, ext: regx.exec(file.name)[1], progress: 0 });
			this.processFileUploadQueue();
		}
	}

	handleFileDrop(fileList) {
		const fileCount: number = fileList.length;
		const key = this.currentNode.key;
		const path = atob(key) + '/';
		const regx = /(?:\.([^.]+))?$/;
		for (let i = 0; i < fileCount; i++) {
			const file = fileList[i];
			const fileObj = { parent: key, file: file }
			this.uploadQueue.push(fileObj);
			this.uploadQueueList.unshift({ key: btoa(path + file.name), file: fileObj, ext: regx.exec(file.name)[1], progress: 0 });
			this.processFileUploadQueue();
		}
	}

	processFileUploadQueue(force = false) {
		if (force || !this.uploadingFile) {
			const reader: any = new FileReader();
			reader.onloadend = (e: any) => {
				const document = { fileName: this.uploadingFile.file.name, fileType: this.uploadingFile.file.type, fileData: e.target.result };
				this.service.createDocument(this.uploadingFile.parent, document, (progress) => {
					this.uploadFileProgress(btoa(atob(this.uploadingFile.parent) + '/' + this.uploadingFile.file.name), progress);
				}).then(data => {
					if (data.document && this.currentNode.key == this.uploadingFile.parent) {
						if (data.action == 'added') {
							this.elements.push(data.document);
							this.setCounts();
						} else {
							const object = this.elements.find(elem => {
								return elem.key == data.document.key;
							});
							object.size = data.document.size;
							object.modified = data.document.modified;
						}
						this.token = Math.random();
					}
					if (this.uploadQueue.indexOf(this.uploadingFile) > -1) {
						this.uploadQueue.splice(this.uploadQueue.indexOf(this.uploadingFile), 1);
					}
					this.processFileUploadQueue(true);
				}, () => {
					if (this.uploadQueue.indexOf(this.uploadingFile) > -1) {
						this.uploadQueue.splice(this.uploadQueue.indexOf(this.uploadingFile), 1);
					}
					this.uploadFileProgress(btoa(atob(this.uploadingFile.parent) + '/' + this.uploadingFile.file.name), -1);
					this.processFileUploadQueue(true);
				});
			}
			if (this.uploadQueue.length > 0) {
				this.uploadingFile = this.uploadQueue[0];
				reader.servie = this.service;
				reader.readAsDataURL(this.uploadingFile.file);
			} else {
				delete this.uploadingFile;
			}
		}
	}

	uploadFileProgress(key, progress) {
		const item = this.uploadQueueList.find(item => {
			return item.key == key;
		});
		if (item) {
			item.progress = progress;
		}
	}

	cancelFileupload(item) {
		const index = this.uploadQueue.indexOf(item.file);
		if (index != -1) {
			this.uploadQueue.splice(index, 1);
		}
		item.progress = -1;
	}

	retryFileupload(item) {
		if (this.uploadQueue.indexOf(item.file) == -1) {
			this.uploadQueue.push(item.file);
			this.processFileUploadQueue();
		}
		item.progress = 0;
	}

	closeFileupload(item) {
		if (this.uploadQueue.length > 0) {
			const dialogRef = this.dialog.open(ConfirmPopupComponent);
			this.onKeyActive = false;
			dialogRef.componentInstance.dialogTitle = 'Cancel upload ?';
			dialogRef.componentInstance.message = 'Your upload is not complete. Would you like to cancel the upload?';
			dialogRef.componentInstance.cancelText = 'CONTINUE UPLOAD';
			dialogRef.componentInstance.confirmText = 'CANCEL UPLOAD';
			dialogRef.afterClosed().subscribe(result => {
				this.onKeyActive = true;
				if (result == true) {
					this.uploadQueue = [];
					this.uploadQueueList = [];
				}
			});
		} else {
			this.uploadQueue = [];
			this.uploadQueueList = [];
		}
	}

	downloadDocument() {
		this.hideContextMenu();
		if (this.privileges.view != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to access content');
			return;
		}
		const object = this.elements.find(elem => {
			return elem.selected && elem.baseType == 'document';
		});
		if (object) {
			this.service.getDocument(object.key).then(data => {
				if (data.document) {
					const element = document.createElement('a');
					element.setAttribute('href', URL.createObjectURL(this.service.b64BlobConversion(data.document.content, data.document.contentType)));
					element.setAttribute('target', '_blank');
					element.setAttribute('download', data.document.name);
					element.style.display = 'none';
					document.body.appendChild(element);
					element.click();
					document.body.removeChild(element);
				} else {
					this.utilityService.showAlerts('Failed to download file');
				}
			}, () => {
				this.utilityService.showAlerts('Failed to download file');
			});
		}
	}

	previewObject(object?) {
		if (this.privileges.view != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to access content');
			return;
		}
		if (!object) {
			object = this.elements.find(elem => {
				return elem.selected && elem.baseType == 'document';
			});
		}
		if (object) {
			const dialogRef = this.dialog.open(TrendzFilePreviewComponent);
			this.onKeyActive = false;
			dialogRef.componentInstance.fileProviderService = this.service;
			dialogRef.componentInstance.file = object;
			dialogRef.componentInstance.files = DriveElementFilterPipe.prototype.transform(this.elements, 'document', this.token);
			dialogRef.afterClosed().subscribe(data => {
				this.onKeyActive = true;
			});
		}
	}

	renameObject() {
		this.hideContextMenu();
		if (this.privileges.edit != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to edit content');
			return;
		}
		const object = this.elements.find(elem => {
			return elem.selected;
		});
		if (object) {
			const type = object.baseType == 'document' ? 'File' : 'Folder';
			const dialogRef = this.dialog.open(TrendzDriveInputModalComponent);
			this.onKeyActive = false;
			dialogRef.componentInstance.dialogTitle = 'Rename ' + type;
			dialogRef.componentInstance.placeHolder = 'Untitled ' + type;
			dialogRef.componentInstance.input = object.name;
			dialogRef.afterClosed().subscribe(title => {
				this.onKeyActive = true;
				if (title) {
					object.renaming = true;
					this.service.renameObject(object.key, title).then(data => {
						if (data.object) {
							object.renaming = false;
							object.key = data.object.key;
							object.name = data.object.name;
							object.modified = data.object.modified;
							this.utilityService.showAlerts(type + ' renamed');
						}
					}, () => {
						this.utilityService.showAlerts('Failed to rename ' + type);
						object.renaming = false;
					});
				}
			});
		}
	}

	removeObject() {
		this.hideContextMenu();
		if (this.elements.length > 0 && this.counts.selected > 0) {
			if (this.privileges.delete != 4) {
				this.utilityService.showAlerts('You don\'t have the permission to delete content');
				return;
			}
			const dialogRef = this.dialog.open(ConfirmPopupComponent);
			this.onKeyActive = false;
			dialogRef.componentInstance.dialogTitle = 'Confirm';
			dialogRef.componentInstance.message = 'One or more files/folders will be removed permanently';
			dialogRef.componentInstance.confirmText = 'REMOVE';
			dialogRef.afterClosed().subscribe(result => {
				this.onKeyActive = true;
				if (result == true) {
					for (let element of this.elements) {
						if (element.selected) {
							element.deleting = true;
							this.service.removeObject(element.key).then(data => {
								if (data.key) {
									let index = this.elements.findIndex(elem => {
										return elem.key == data.key;
									});
									this.elements.splice(index, 1);
									this.setCounts();
									this.token = Math.random();
								}
							}, err => {
								element = this.elements.find(elem => {
									return elem.key == err.key;
								});
								element.deleting = false;
							});
						}
					}
				}
			});
		}
	}

	copyObjects() {
		this.hideContextMenu();
		if (this.elements.length > 0 && this.counts.selected > 0) {
			if (this.privileges.create != 4) {
				this.utilityService.showAlerts('You don\'t have the permission to modify contents');
				return;
			}
			this.copyMode = 'copy';
			this.copyFrom = this.currentNode.key;
			this.copiedElements = [];
			for (const elem of this.elements) {
				if (elem.selected) {
					this.copiedElements.push(elem.key);
				}
			}
		}
	}

	cutObjects() {
		if (this.elements.length > 0 && this.counts.selected > 0) {
			if (this.privileges.delete != 4) {
				this.utilityService.showAlerts('You don\'t have the permission to remove contents');
				this.hideContextMenu();
				return;
			}
			this.copyObjects();
			this.copyMode = 'move';
		}
	}

	pasteObjects(onFolder = false) {
		this.hideContextMenu();
		if (this.privileges.create != 4) {
			this.utilityService.showAlerts('You don\'t have the permission to upload contents');
			return;
		}
		let copyTo = this.currentNode.key;
		if (onFolder) {
			const element = this.elements.find(elem => {
				return elem.focused;
			});
			if (element) {
				copyTo = element.key;
			}
		}
		if (this.copyFrom && this.copiedElements.length > 0) {
			this.service.copyPaste(this.copiedElements, this.copyFrom, copyTo, this.copyMode).then(response => {
				if (response.objects) {
					for (const element of response.objects) {
						if (element.to == this.currentNode.key && element.to != element.from) {
							this.elements.push(element.object);
						} else if (element.mode == 'move' && element.from == this.currentNode.key) {
							const index = this.elements.findIndex(elem => {
								return elem.key == element.key;
							});
							this.elements.splice(index, 1);
						}
					}
					this.setCounts();
					this.token = Math.random();
				}
				this.utilityService.showAlerts(response.message);
			}, err => { this.utilityService.showAlerts(err.message); });
			this.copiedElements = [];
		}
	}

	onClick(event) {
		this.service.currentComponentInstance = this.componentInstance;
		const target = $(event.target);
		if (!target.hasClass('context-menu') && target.parents('.context-menu').length == 0) {
			this.hideContextMenu();
		}
		if (!target.hasClass('drive-element') && target.parents('.drive-element').length == 0
			&& target.parents('.context-menu').length == 0 && target.parents('.toolbar-wrap').length == 0) {
			this.clearSelections();
		}
	}

	onContextMenu(event) {
		this.service.currentComponentInstance = this.componentInstance;
		this.hideContextMenu();
		let target = $(event.target);
		event.preventDefault();
		if ((target.hasClass('drive-element') && target.hasClass('folder'))) {
			this.contextMenuScope = 'folder';
		} else if (target.parents('.drive-element.folder').length > 0) {
			target = $(target.parents('.drive-element.folder').get(0));
			this.contextMenuScope = 'folder';
		} else if (target.hasClass('drive-element') && target.hasClass('document')) {
			this.contextMenuScope = 'document';
		} else if (target.parents('.drive-element.document').length > 0) {
			target = $(target.parents('.drive-element.document').get(0));
			this.contextMenuScope = 'document';
		} else if (target.hasClass('content-wrap') || target.parents('.content-wrap').length > 0) {
			this.contextMenuScope = 'drive';
		}
		if (target.hasClass('drive-element')) {
			this.selectElement(target.attr('key'), event.ctrlKey);
		}
		if (this.contextMenuScope) {
			this.showContextMenu(target, true, event);
		}
	}

	showContextMenu(target, onMouse, event) {
		const menu = $(this.contextMenu.nativeElement);
		const drive = $(this.trendzDrive.nativeElement);
		const mousePosition: any = {
		};
		const menuPostion: any = {
		};
		const menuDimension: any = {
		};
		menuDimension.x = menu.outerWidth();
		menuDimension.y = menu.outerHeight();
		if (onMouse) {
			mousePosition.x = event.pageX;
			mousePosition.y = event.pageY;
		} else {
			const offset = $(target).offset();
			mousePosition.x = offset.left;
			mousePosition.y = offset.top + target.offsetHeight;
		}

		const driveOffset = drive.offset();
		mousePosition.x -= driveOffset.left;
		mousePosition.y -= driveOffset.top;

		if (mousePosition.x + menuDimension.x > drive.width()) {
			menuPostion.x = mousePosition.x - menuDimension.x;
			if (!onMouse) {
				menuPostion.x += target.offsetWidth;
			}
		} else {
			menuPostion.x = mousePosition.x;
		}
		if (mousePosition.y + menuDimension.y > drive.height()) {
			menuPostion.y = mousePosition.y - menuDimension.y;
		} else {
			menuPostion.y = mousePosition.y;
		}
		this.contextMenuStyle = { maxHeight: '300px', zIndex: 9, opacity: 1, left: menuPostion.x + 'px', top: menuPostion.y + 'px' };
		this.contextMenuActive = true;
	}

	hideContextMenu() {
		this.contextMenuScope = '';
		this.contextMenuStyle = { maxHeight: '0px', opacity: 0, zIndex: -1 };
		this.contextMenuActive = false;
	}

	onKey(event) {
		if (this.service.currentComponentInstance == this.componentInstance && this.onKeyActive && $(this.trendzDrive.nativeElement).is(':visible')) {
			switch (event.keyCode) {
				case 27:
					if (this.contextMenuActive) {
						this.hideContextMenu();
					} else {
						this.clearSelections();
						this.copiedElements = [];
					}
					break;
			}
			switch (event.keyCode) {
				case 37:
					this.leftArrowPressed(event.shiftKey, event.ctrlKey);
					break;
				case 39:
					this.rightArrowPressed(event.shiftKey, event.ctrlKey);
					break;
				case 32:
					this.keyboardSelect(event.ctrlKey);
					break;
				case 65:
					if (event.ctrlKey) {
						event.preventDefault();
						this.selectAll();
					}
					break;
				case 46:
					this.removeObject();
					break;
				case 67:
					if (event.ctrlKey) {
						this.copyObjects();
					}
					break;
				case 88:
					if (event.ctrlKey) {
						this.cutObjects();
					}
					break;
				case 86:
					if (event.ctrlKey) {
						this.pasteObjects();
					}
					break;
			}
		}
	}

	leftArrowPressed(shift, ctrl) {
		this.hideContextMenu();
		if (this.elements.length > 0) {
			const curIndex = this.elements.findIndex(elem => {
				return elem.focused;
			});
			if (curIndex > 0) {
				if (shift) {
					this.selectElement(this.elements[curIndex - 1].key, shift);
				} else if (ctrl) {
					this.elements[curIndex].focused = false;
					this.elements[curIndex - 1].focused = true;
				} else {
					this.selectElement(this.elements[curIndex - 1].key);
				}
			}
		}
	}

	rightArrowPressed(shift, ctrl) {
		this.hideContextMenu();
		if (this.elements.length > 0) {
			const curIndex = this.elements.findIndex(elem => {
				return elem.focused;
			});
			if (curIndex < this.elements.length - 1) {
				if (shift) {
					this.selectElement(this.elements[curIndex + 1].key, shift);
				} else if (ctrl) {
					this.elements[curIndex].focused = false;
					this.elements[curIndex + 1].focused = true;
				} else {
					this.selectElement(this.elements[curIndex + 1].key);
				}
			}
		}
	}

	keyboardSelect(ctrl) {
		this.hideContextMenu();
		if (this.elements.length > 0) {
			const element = this.elements.find(elem => {
				return elem.focused;
			});
			if (element) {
				if (element.selected) {
					element.selected = false;
					this.setCounts(1);
				} else {
					this.selectElement(element.key, ctrl);
				}
			}
		}
	}
}
