import { throwError as observableThrowError, of as observableOf, Subject, Observable } from 'rxjs';

import { catchError, distinctUntilChanged, debounceTime, startWith, map, takeUntil } from 'rxjs/operators';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import { DatePipe } from '@angular/common';
import { Injectable, ElementRef } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { ChoiceList, Lov } from 'app/hcm/persons/persons/_models';
import { WorkflowVariableModel } from './../models/shared-models';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import { EventService } from 'app/shared/services/event.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FleetApiService } from './fleet-api.service';
import { AutocompleteConfig } from 'app/shared/models/autocomplete-config.model';
import { AcmApiService } from './acm-api.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UtilityService implements OnDestroy {
    dateformat = 'dd-MMM-yyyy';
    timeformat = 'HH:mm:ss';
    timeformatwithoutsec = 'HH:mm';
    dateTimeFormat = 'dd-MMM-yyyy HH:mm:ss';
    appMenus;
    bookmarks;
    allowedUrls = ['/login', '/embed', '/mailbox', '/scheduler', '/not-found', '/loading', '/trendz-drive', '/profile'];
    bookmarkedTaskFlows: number[] = [];
    showQuickMenu = false;
    showSnackBar = true;
    array: ChoiceList[] = [];
    private ngUnsubscribe: Subject<any> = new Subject<any>();

    constructor(private apiService: ApiService
        , private snackBar: MatSnackBar
        , private eventService: EventService
        , private fleetApiService: FleetApiService
        , private acmApiService: AcmApiService
        , private userService: UserService
        , private breakpointObserver: BreakpointObserver) {
    }

    fetchMenuData(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.appMenus) {
                this.fetchMenuDataFromDB()
                    .then(res => {
                        resolve(res);
                    }, () => {
                        reject();
                    });
            } else {
                resolve(this.appMenus);
            }
        });
    }

    fetchMenuDataFromDB(): Promise<any> {
        this.appMenus = '';
        return new Promise((resolve, reject) => {
            this.apiService.get('/administration/menu').pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(res => {
                    if (res && res.tnzMenu) {
                        this.appMenus = JSON.parse(JSON.stringify(res.tnzMenu));
                        resolve(this.appMenus);
                    } else {
                        reject();
                    }
                }, (err) => {
                    console.log(err);
                    reject();
                });
        });
    }

    getLoggedInUser() {
        return this.userService.currentUserSubject.getValue();
    }

    fetchBookmarks(): Promise<any> {
        return new Promise((resolve, reject) => {
            if (!this.bookmarks) {
                this.fetchBookmarksFromDB()
                    .then(res => {
                        resolve(res);
                    }, () => {
                        reject();
                    });
            } else {
                resolve(this.bookmarks);
            }
        });
    }

    fetchBookmarksFromDB(): Promise<any> {
        this.bookmarks = '';
        return new Promise((resolve, reject) => {
            this.apiService.get('/administration/bookmarks').pipe(
                takeUntil(this.ngUnsubscribe))
                .subscribe(res => {
                    if (res && res.bookmarks) {
                        this.bookmarks = JSON.parse(JSON.stringify(res.bookmarks));
                        resolve(this.bookmarks);
                    } else {
                        reject(false);
                    }
                }, () => {
                    reject(false);
                });
        });
    }

    saveBookMark(bookmark): Observable<any> {
        return this.apiService.post('/administration/bookmarks', {bookmark: bookmark}).pipe(
            map(data => {
                return data
            }));
    }

    deleteBookmark(bookmarkId): Observable<any> {
        if (bookmarkId) {
            return this.apiService.delete('/administration/bookmarks/' + bookmarkId).pipe(
                map(data => data))
        }
    }

    formatDate(date) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'dd-MM-yyyy');
        } else {
            return '';
        }
    }

    formatDateTime(date) {
        if (date && new Date(date).toString() != 'Invalid Date') {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'yyyy-MM-dd HH:mm:ss');
        } else {
            return '';
        }
    }

    formatDocDate(date) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'MM-dd-yyyy');
        } else {
            return '';
        }
    }

    formatEditableTableDate(date: string) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'MM/dd/yyyy');
        } else {
            return '';
        }
    }

    formatToNormalDate(date: string) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'dd/MM/yyyy');
        } else {
            return '';
        }
    }

    formatToCalDate(date) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            try {
                return new Date(datePipe.transform(date, 'medium'));
            } catch (err) {
                return date;
            }
        } else {
            return null;
        }
    }


    formatToDisplayDate(date: string) {
        if (date) {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'yyyy-MM-dd');
        } else {
            return '';
        }
    }

    formatToListDisplayDate(date: string) {
        if (date && date != 'Invalid Date') {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'dd-MMM-yyyy');
        } else {
            return '';
        }
    }

    formatToDateTime(date: string) {
        if (date && date != 'Invalid Date') {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'dd-MMM-yyyy HH:mm');
        } else {
            return '';
        }
    }


    formatToTime(date: string) {
        if (date && date != 'Invalid Date') {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(date, 'HH:mm');
        } else {
            return '';
        }
    }

    actionButtonLabel: string = 'Retry';
    action: boolean = false;
    setAutoHide: boolean = true;
    autoHide: number = 3000;
    addExtraClass: boolean = true;

    showAlerts(msg: string, force = false, duration = 0) {
        if (this.showSnackBar || force) {
            let config = new MatSnackBarConfig();
            config.duration = (duration || this.autoHide);
            config.panelClass = this.addExtraClass ? ['party'] : undefined
            this.snackBar.open(msg, this.action ? this.actionButtonLabel : undefined, config);
        }
        // alert(msg);
    }

    /*
    *   Custom Form Validators
    */
    validateForm(formGroup: FormGroup) {
        Object.keys(formGroup.controls).forEach(key => {
            const fc = formGroup.get(key);
            if (fc.validator) {
                fc.markAsTouched();
            }
        });
    }

    shorCodeValidator(fc: FormControl) {
        let regex = new RegExp(/^[a-zA-Z0-9_]*$/);
        return fc.value && !regex.test(fc.value) ? {shortcode: true} : null;
    }

    emailValidator(fc: FormControl) {
        return fc.value ? Validators.email(fc) : null;
    }

    integerValidator(fc: FormControl) {
        let regex = new RegExp('^-?\\d*$');
        return fc.value && !regex.test(fc.value) ? {integer: true} : null;
    }

    positiveNumberValidator(fc: FormControl) {
        let regex = new RegExp(/^(?![0.]+$)\d+(\.\d{1,2})?$/);
        // ^\d + $
        return fc.value && !regex.test(fc.value) ? {pnum: true} : null;
    }

    numberValidator(fc: FormControl) {
        let regex = new RegExp('^-?\\d*\.?\\d*$');
        return fc.value && !regex.test(fc.value) ? {number: true} : null;
    }

    urlValidator(fc: FormControl) {
        let regex = new RegExp('/[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi');
        return fc.value && !regex.test(fc.value) ? {url: true} : null;
    }

    yearValidator(fc: FormControl) {
        let regex = new RegExp('^\\d*$');
        return fc.value && (fc.value.toString().length != 4 || !regex.test(fc.value)) ? {year: true} : null;
    }

    timeValidator(fc: FormControl) {
        let time = fc.value;
        if (time) {
            let regex = new RegExp('[0-9][0-9]:[0-9][0-9]');
            let arr = time.split(':');
            if (regex.test(time) && time.length == 5) {
                let hour = Number(arr[0]);
                let min = Number(arr[1]);
                if (hour >= 24) {
                    return {hour: true}
                } else if (min >= 60) {
                    return {min: true}
                } else {
                    return null;
                }
            }
            return {format: true};
        }
    }

    /*
    *   Auto Complete helper methods
    */

    autoCompleteBranchnameFn(ntl: any): string {
        return ntl ? ntl.branchName : '';
    }

    autoCompleteDisplayFn(ntl: ChoiceList): string {
        return ntl ? ntl.label : '';
    }

    autoCompleteDisplayFnWithValue(ntl: ChoiceList): string {
        return ntl ? ntl.label + (ntl.value ? ' [' + ntl.value + '] ' : '') + '' : '';
    }

    autoCompleteDisplayShortCodeFn(ntl: Lov): string {
        return ntl ? ntl.shortCode : '';
    }

    autoCompleteDisplayValueFn(ntl: ChoiceList): string {
        return ntl ? ntl.value : '';
    }


    autoCompleteDisplayForLookups(ntl: any): string {
        return ntl ? ntl.meaning : '';
    }


    autoCompleteBenefitGroupDisplayFn(ntl: any): string {
        return ntl ? (ntl.name + (ntl.label ? (' [ ' + ntl.label + ' ]') : '')) : '';
    }

    autoCompleteDisplayValue2(ntl: any): string {
        return ntl ? (ntl.value2 ? ntl.value2 : ntl.label) : '';
    }

    autoCompleteDisplayValue3(ntl: any): string {
        return ntl ? ntl.value3 : '';
    }

    autoCompleteDisplayValueWithCode(ntl: any): string {
        return ntl ? (ntl.value2 + (ntl.value4 ? (' [ ' + ntl.value4 + ' ]') : '')) : '';
    }

    autoCompleteDisplayValueWithCodeTime(ntl: any): string {
        return ntl ? (ntl.value2 + (ntl.value4 ? (' [ ' + ntl.value4 + ' ]') : '') + (ntl.value10 ? (' [ ' + ntl.value10 + ' ]') : '') + (ntl.value5 ? (' [ ' + ntl.value5 + ' ]') : '')) : '';
    }

    autoCompleteDisplayValueWithCode5(ntl: any): string {
        return ntl ? (ntl.value5 ? ntl.label + ' [' + ntl.value5 + '] ' : ntl.label) : '';
    }

    autoCompleteDisplayFullnameWithCode(ntl: any): string {
        return ntl ? (ntl.value3 + ' [ ' + ntl.label + ' ]') : '';
    }

    autoCompleteDisplayNameForPersonLOV(ntl: any): string {
        return ntl ? (ntl.value3 ? '[ ' + ntl.value3 + ' ] ' : '') + ntl.label : '';
    }

    autoCompleteDisplayNameForPersonLOV2(ntl: any): string {
        return ntl ? ('[ ' + ntl.personnelNumber + ' ] ' + ntl.firstName + ' ' + ntl.lastName) : '';
    }

    autoCompleteDisplayPersonName(ntl: any): string {
        return ntl ? ntl.personName : '';
    }

    autoCompleteDisplayPersonNameWithNumber(ntl: any): string {
        return ntl ? ntl.personName + '[ ' + ntl.personnelNumber + ' ] ' : '';
    }

    autoCompleteValidator(fc: FormControl) {
        return typeof fc.value === 'object' || fc.value == '' ? null : {invalid: true};
    }

    autoCompleteRequiredValidator(fc: FormControl) {
        return (typeof fc.value === 'object' && fc.value.value != '') ? null : fc.value != '' ? {invalid: true} : {required: true};
    }

    populateAutoComplete(options: ChoiceList[], fc: AbstractControl, allowNew?: string): Observable<any> {
        let option = {value: '_NEW', label: '-- new entry --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.label : option),
            map(option => this.filterAutoComplete(options, option, allowNew)),);
    }

    populateAutoCompleteCommon(options: any[], fc: AbstractControl, whatToFilter, allowNew?: string): Observable<any> {
        let option = {value: '_NEW', whatToFilter: '-- new entry --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.whatToFilter : option),
            map(option => this.filterAutoCompleteCommon(options, option, whatToFilter, allowNew)),);
    }

    filterAutoComplete(options: ChoiceList[], value: string, allowNew?: string): ChoiceList[] {
        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => new RegExp(`^${value}`, 'gi').test(option.label) || new RegExp(`^${value}`, 'gi').test(option.value));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', label: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    autoCompleteDisplayAsset(ntl: any): string {
        return ntl ? (ntl.value2 + (ntl.value3 ? (' [ ' + ntl.value3 + ' ]') : '')) : '';
    }

    filterAutoCompleteCommon(options: any[], value: string, whatToFilter, allowNew?: string): any[] {
        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => new RegExp(`${value}`, 'gi').test(option[whatToFilter]));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', label: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    populatebankAutoComplete(options: any[], fc: AbstractControl, allowNew?: string): Observable<any> {

        let option = {value1: '_NEW', value5: '-- new entry --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.value5 : option),
            map(option => this.filterAutoCompleteBank(options, option, allowNew)),);
    }

    filterAutoCompleteBank(options: any[], value: string, allowNew?: string): ChoiceList[] {
        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => (new RegExp(`^${value}`, 'gi').test(option.value5) || new RegExp(`^${value}`, 'gi').test(option.value2)));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value1: '_NEW', value5: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }

        return filtered;
    }

    populateAutoCompleteSeparation(options: any[], fc: AbstractControl, allowNew?: string): Observable<any> {

        let option = {value: '_NEW', separationRequestNo: '-- new entry --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.separationRequestNo : option),
            map(option => this.filterAutoCompleteSeparation(options, option, allowNew)),);
    }

    filterAutoCompleteSeparation(options: any[], value: string, allowNew?: string): ChoiceList[] {

        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => new RegExp(`^${value}`, 'gi').test(option.separationRequestNo) || new RegExp(`^${value}`, 'gi').test(option.personId) || new RegExp(`^${value}`, 'gi').test(option.person));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', separationRequestNo: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    populateAutoCompleteEND(options: any[], fc: AbstractControl, allowNew?: string): Observable<any> {
        let option = {value: '_NEW', elementName: '-- new entry --', shortCode: ' -- sc --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.elementName : option),
            map(option => this.filterAutoCompleteEND(options, option, allowNew)),);
    }

    populateAutoCompleteForLookUps(options: any[], fc: AbstractControl, allowNew?: string): Observable<any> {
        let option = {value: '_NEW', meaning: '-- new entry --', lookupCode: ' -- sc --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.meaning : option),
            map(option => this.filterAutoCompleteForLookUps(options, option, allowNew)),);
    }

    filterAutoCompleteForLookUps(options: any[], value: string, allowNew?: string): any[] {
        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => new RegExp(`${value}`, 'gi').test(option.meaning));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', meaning: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    filterAutoCompleteEND(options: any[], value: string, allowNew?: string): ChoiceList[] {
        let filtered = [];
        if (value) {
            if (typeof value !== 'object') {
                value = this.replaceRegularExpCharacters(value);
            }
            filtered = options.filter(option => new RegExp(`${value}`, 'gi').test(option.elementName));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', elementName: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    populateAutoCompleteUsingValue(options: any[], fc: AbstractControl, allowNew?: string): Observable<any> {
        let option = {value: '_NEW', label: '-- new entry --'};
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.value2 : option),
            map(option => this.filterAutoCompleteUsingValue(options, option, allowNew)),);
    }

    filterAutoCompleteUsingValue(options: any[], value: string, allowNew?: string): any[] {
        let filtered = [];
        if (value) {

            value = this.replaceRegularExpCharacters(value);
            filtered = options.filter(option =>
                (new RegExp(`^${value}`, 'gi').test(option.value2)
                    || new RegExp(`^${value}`, 'gi').test(option.value4))
                || new RegExp(`^${value}`, 'gi').test(option.value6));
        } else {
            filtered = options.slice(0);
        }
        if (allowNew) {
            let option = {value: '_NEW', label: '-- new ' + allowNew + ' --'};
            filtered.push(option);
        }
        return filtered;
    }

    populateAutoCompleteUsingBranchname(options: any[], fc: AbstractControl): Observable<any> {
        return fc.valueChanges.pipe(
            startWith(null),
            map(option => option && typeof option === 'object' ? option.branchName : option),
            map(option => this.filterAutoCompleteBranchName(options, option)),);
    }

    filterAutoCompleteBranchName(options: any[], value: string): any[] {
        let filtered = [];
        if (value) {
            value = this.replaceRegularExpCharacters(value);
            filtered = options.filter(option => (new RegExp(`^${value}`, 'gi').test(option.branchName)));
        } else {
            filtered = options.slice(0);
        }
        return filtered;
    }

    populateAutoCompleteFromDB(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter = null, baseUrl = '/lovs/'): Observable<any> {
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        } else if (application == 'ACM') {
            service = this.acmApiService;
        }

        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };
        return fc.valueChanges.pipe(
            startWith(null),
            debounceTime(400),
            distinctUntilChanged(),
            map(data => data && typeof data === 'object' ? data.label : data),
            map(data => {

                let json;
                if (data && data.startsWith('+')) {
                    data = data.replace('+', '');
                }
                if (data) {
                    if (filterType == 'new') {
                        json = [];
                        filterAttributes.forEach(field => {
                            json.push({
                                'type': 'item',
                                'con': 'or',
                                'attr': field,
                                'operator': 'startsWith',
                                'value': data
                            });
                        });
                    } else {
                        json = {};
                        filterAttributes.forEach(field => {
                            json[field] = data;
                        });
                    }
                }
                if (addLimit) {
                    params = params.set('limit', '30');
                    params = params.set('offset', '1');
                }
                params = json ? params.set(filter, JSON.stringify(json)) : params.delete(filter);
                return service.get((baseUrl + url), params)
                    .map(res => {
                        if (resultFilter) {
                            return resultFilter(res[jsonKey]);
                        } else {
                            return res[jsonKey];
                        }
                    });
            }),);
    }

    populateAutoCompleteEmployeeFromDB(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter = null): Observable<any> {
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        } else if (application == 'ACM') {
            service = this.acmApiService;
        }
        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };
        return fc.valueChanges.pipe(
            startWith(null),
            debounceTime(400),
            distinctUntilChanged(),
            map(data => data && typeof data === 'object' ? data.label : data),
            map(data => {

                let json;
                if (data && data.startsWith('+')) {
                    data = data.replace('+', '');
                }
                if (data) {
                    if (filterType == 'new') {
                        json = [];
                        filterAttributes.forEach(field => {
                            json.push({
                                'type': 'item',
                                'con': 'or',
                                'attr': field,
                                'operator': 'startsWith',
                                'value': data
                            });
                        });
                    } else {
                        json = {};
                        filterAttributes.forEach(field => {
                            json[field] = data;
                        });
                    }
                }
                if (addLimit) {
                    params = params.set('limit', '30');
                    params = params.set('offset', '1');
                }
                params = json ? params.set(filter, JSON.stringify(json)) : params.delete(filter);
                return service.get(('/' + url), params)
                    .map(res => {
                        if (resultFilter) {
                            return resultFilter(res[jsonKey]);
                        } else {
                            return res[jsonKey];
                        }
                    });
            }),);
    }

    populateAutoCompleteFromDBWithNgModel(
        data: any, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter: string = null): Observable<any> {
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };
        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        if ((data && typeof data === 'object' ? data = data.label : data) || data == '') {
            data = data.replace('+', '');
            let json;
            if (data) {
                if (filterType == 'new') {
                    json = [];
                    filterAttributes.forEach(field => {
                        json.push({
                            'type': 'item',
                            'con': 'or',
                            'attr': field,
                            'operator': 'startsWith',
                            'value': data
                        });
                    });
                } else {
                    json = {};
                    filterAttributes.forEach(field => {
                        json[field] = data;
                    });
                }
            }
            if (addLimit) {
                params = params.set('limit', '30');
                params = params.set('offset', '1');
            }
            params = json ? params.set(filter, JSON.stringify(json)) : params.delete(json);
            return service.get(('/' + url), params)
                .map(res => {
                    if (resultFilter) {
                        return resultFilter(res[jsonKey]);
                    } else {
                        return res[jsonKey];
                    }
                });
        }
    }


    populateAutoCompleteFromDBWithNgModelV2(
        data: any,
        url: string,
        filterAttributes: string[] = [],
        andFilter: Map<string, any>,
        jsonKey: string = url,
        params: HttpParams = new HttpParams(),
        resultFilter = null,
        service: any = this.apiService,
        key: string = 'label',
        filter: string = 'filter'
    ): Observable<any> {
        let group = [];
        let andParams = [];
        if (andFilter && andFilter.size > 0) {
            andFilter.forEach((value, key) => {
                andParams.push({
                    'type': 'item',
                    'con': 'and',
                    'attr': key,
                    'operator': 'startsWith',
                    'value': value
                })
            });
            let andGroup = {
                'type': 'group',
                'con': 'and',
                items: andParams
            }
            group.push(andGroup);
        }
        if (data && typeof data !== 'object' && data.startsWith('+')) {
            data = data.replace('+', '');
        }
        if (data) {
            let json = [];
            if ((data && typeof data === 'object' ? data = data[key] : data) || data == '') {
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': data
                    });
                });
            }
            let orGroup = {
                'type': 'group',
                'con': 'and',
                items: json
            }
            group.push(orGroup);
        }

        if (group && group.length > 0) {
            params = params.set(filter, JSON.stringify(group));
        } else {
            params = params.delete(filter);
        }

        params = params.set('offset', '1');
        params = params.set('limit', '30')
        return service.get(('/' + url), params)
            .map(res => {
                if (resultFilter) {
                    return resultFilter(res[jsonKey]);
                } else {
                    return res[jsonKey];
                }
            });

    }


    config = {
        URL: '',
        JSONKey: '',
        service: '',
        attributes: '',
        resultFilter: '',
        params: '',
        primaryFilters: ''
    }

    populateAutoCompleteFromDBWithNgModelAndExtraFilters(
        data: any,
        key: string,
        config: AutocompleteConfig
    ): Observable<any> {
        let group = [];
        if (data && typeof data !== 'object' && data.startsWith('+')) {
            data = data.replace('+', '');
        }
        if (data) {
            data = !data ? '' : data && typeof data === 'object' ? data[key] : data;
            const primaryFilter = config.primaryFilters.reduce((filter, attribute) => {
                const key = Object.keys(attribute)[0];
                const value = attribute[key];
                const filterItem = {
                    type: 'item',
                    con: 'and',
                    attr: key,
                    operator: 'startsWith',
                    value: value
                }
                filter.push(filterItem);
                return filter;
            }, []);

            const dataFilter = config.filterAttributes.reduce((filter, attribute) => {
                const filterItem = {
                    type: 'item',
                    con: 'and',
                    attr: key,
                    operator: 'startsWith',
                    value: data
                }
                filter.push(filterItem);
                return filter;
            }, []);

            const combinedFilter = {
                type: 'group',
                con: 'and',
                items: Array.prototype.concat([...primaryFilter], dataFilter)
            }

            let params: HttpParams = config.params;
            params = params.set('filter', JSON.stringify(combinedFilter));
            params = params.set('offset', '1');
            params = params.set('limit', '30')

            return config.service.get(('/' + config.URL), params)
                .map(res => {
                    if (config.resultFilter) {
                        return config.resultFilter(res[config.key]);
                    } else {
                        return res[config.key];
                    }
                });
        }
    }


    populateAutoCompletewithNgModel(options: ChoiceList[], data: any, allowNew?: string): any {
        if ((data && typeof data === 'object' ? data = data.label : data) || data == '') {
            if (data) {
                return this.filterAutoComplete(options, data, allowNew)
            }
        }
    }

    populateAutoCompleteFromDBUsingValue(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams()): Observable<any> {
        return fc.valueChanges.pipe(
            startWith(null),
            debounceTime(400),
            distinctUntilChanged(),
            map(data => data && typeof data === 'object' ? data.value2 : data),
            map(data => {
                const json = {};
                if (data && data.startsWith('+')) {
                    data = data.replace('+', '');
                }
                if (data) {
                    filterAttributes.forEach(field => json[field] = data);
                }
                params = json ? params.set('filters', JSON.stringify(json)) : params.delete('filters');
                return this.apiService.get(('/lovs/' + url), params).pipe(
                    map(res => res[jsonKey]));
            }),);
    }


    checkAutoCompleteSelected(options: ChoiceList[], fc: AbstractControl) {
        if (typeof fc.value !== 'object' && fc.value != '') {
            let filtered = this.filterAutoComplete(options, fc.value);
            if (filtered.length == 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedForFLM(options: ChoiceList[], fc: AbstractControl) {
        if (typeof fc.value !== 'object' && fc.value != '') {
            let filtered = this.filterAutoComplete(options, fc.value);
            if (filtered.length >= 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedValue(options: ChoiceList[], fc: AbstractControl) {
        if (fc.value != '') {
            let filtered = this.filterAutoCompleteUsingValue(options, fc.value);
            if (filtered.length == 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedCommon(options: any[], fc: AbstractControl, filterWhat) {
        if (typeof fc.value !== 'object' && fc.value != '') {
            let filtered = this.filterAutoCompleteCommon(options, fc.value, filterWhat);
            if (filtered.length == 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedEND(options: any[], fc: AbstractControl) {
        if (typeof fc.value !== 'object' && fc.value != '') {
            let filtered = this.filterAutoCompleteEND(options, fc.value);
            if (filtered.length == 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedForLookups(options: any[], fc: AbstractControl) {
        if (typeof fc.value !== 'object' && fc.value != '') {
            let filtered = this.filterAutoCompleteForLookUps(options, fc.value);
            if (filtered.length == 1) {
                fc.setValue(filtered[0]);
            }
        }
    }

    checkAutoCompleteSelectedFromDB(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter = null, clearIfValidationFails = false) {
        let value = fc.value;
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        } else if (application == 'ACM') {
            service = this.acmApiService;
        }
        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };

        if (value && typeof value !== 'object' && value.startsWith('+')) {
            value = value.replace('+', '');
        }
        if (value && typeof value !== 'object') {
            let json;
            if (filterType == 'new') {
                json = [];
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': value
                    });
                });
            } else {
                json = {};
                filterAttributes.forEach(field => json[field] = value);
            }
            if (addLimit) {
                params = params.set('limit', '30');
                params = params.set('offset', '1');
            }
            params = json ? params.set(filter, JSON.stringify(json)) : params.delete('filter');
            service.get(('/lovs/' + url), params)
                .pipe(
                    debounceTime(400),
                    distinctUntilChanged(),
                    map(res => res[jsonKey])
                ).subscribe(data => {
                if (data) {

                    if (data.length == 1) {
                        fc.setValue(data[0]);
                    } else if (clearIfValidationFails) {
                        fc.setValue('')
                    }
                }
            });
        }
    }

    checkAutoCompleteEmployeeSelectedFromDB(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter = null) {
        let value = fc.value;
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        } else if (application == 'ACM') {
            service = this.acmApiService;
        }
        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };

        if (value && typeof value !== 'object' && value.startsWith('+')) {
            value = value.replace('+', '');
        }
        if (value && typeof value !== 'object') {
            let json;
            if (filterType == 'new') {
                json = [];
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': value
                    });
                });
            } else {
                json = {};
                filterAttributes.forEach(field => json[field] = value);
            }
            if (addLimit) {
                params = params.set('limit', '30');
                params = params.set('offset', '1');
            }
            params = json ? params.set(filter, JSON.stringify(json)) : params.delete('filter');
            service.get(('/' + url), params).pipe(
                debounceTime(400),
                distinctUntilChanged(),
                map(res => res[jsonKey])
            ).subscribe(data => {
                if (data) {
                    if (data.length == 1) {
                        fc.setValue(data[0]);
                    }
                }
            });
        }
    }

    checkAutoCompleteSelectedFromDBWithNgModel(model, url: string, filterAttributes: string[] = [], jsonKey: string = url,
                                               params: HttpParams = new HttpParams(), resultFilter = null, application = 'HCM', addLimit = false, filterType = 'old', filter = null): Observable<any> {
        let service: any = this.apiService;
        if (application == 'FLM') {
            service = this.fleetApiService;
        }
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };
        if (filter == null) {
            if (application == 'HCM') {
                filter = 'filters'
            } else {
                filter = 'filter'
            }
        }
        if (model && typeof model !== 'object' && model.startsWith('+')) {
            model = model.replace('+', '');
        }
        let json;
        if (model) {
            if (filterType == 'new') {
                json = [];
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': model
                    });
                });
            } else {
                json = {};
                filterAttributes.forEach(field => json[field] = model);
            }
        }

        if (addLimit) {
            params = params.set('limit', '30');
            params = params.set('offset', '1');
        }
        params = json ? params.set(filter, JSON.stringify(json)) : params.delete('filter');
        return service.get(('/' + url), params).pipe(
            debounceTime(400),
            distinctUntilChanged(),
            map(res => res[jsonKey]))
    }

    checkAutoCompleteSelectedFromDBWithNgModelV2(
        data: any,
        url: string,
        filterAttributes: string[] = [],
        andFilter: Map<string, any>,
        jsonKey: string = url,
        params: HttpParams = new HttpParams(),
        resultFilter = null,
        service: any,
        key: string = 'label'
    ): Observable<any> {
        let group = [];
        let andParams = [];
        if (andFilter && andFilter.size > 0) {
            andFilter.forEach((value, key) => {
                andParams.push({
                    'type': 'item',
                    'con': 'and',
                    'attr': key,
                    'operator': 'startsWith',
                    'value': value
                })
            });

            let andGroup = {
                'type': 'group',
                'con': 'and',
                items: andParams
            }
            group.push(andGroup);
        }
        if (data && typeof data !== 'object' && data.startsWith('+')) {
            data = data.replace('+', '');
        }
        if (data) {
            let json = [];
            if ((data && typeof data === 'object' ? data = data[key] : data) || data == '') {
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': data
                    });
                });
            }
            let orGroup = {
                'type': 'group',
                'con': 'and',
                items: json
            }
            group.push(orGroup);
        }

        if (group && group.length > 0) {
            params = params.set('filter', JSON.stringify(group));
        } else {
            params = params.delete('filter');
        }

        params = params.set('offset', '1');
        params = params.set('limit', '30')

        return service.get(('/' + url), params).pipe(
            debounceTime(400),
            distinctUntilChanged(),
            map(res => res[jsonKey])
        )
    }

    checkACSelectedFromDB(
        fc: AbstractControl, url: string, filterAttributes: string[] = [], jsonKey: string = url,
        params: HttpParams = new HttpParams(), resultFilter = null, service: any = this.apiService, addLimit = false, filterType = 'old', filter = 'filters') {
        let value = fc.value;
        let group = {
            'type': 'item',
            'con': 'or',
            'attr': '',
            'operator': 'startsWith',
            'value': ''
        };

        if (value && typeof value !== 'object' && value.startsWith('+')) {
            value = value.replace('+', '');
        }
        if (value && typeof value !== 'object') {
            let json;
            if (filterType == 'new') {
                json = [];
                filterAttributes.forEach(field => {
                    json.push({
                        'type': 'item',
                        'con': 'or',
                        'attr': field,
                        'operator': 'startsWith',
                        'value': value
                    });
                });
            } else {
                json = {};
                filterAttributes.forEach(field => json[field] = value);
            }
            if (addLimit) {
                params = params.set('limit', '30');
                params = params.set('offset', '1');
            }
            params = json ? params.set(filter, JSON.stringify(json)) : params.delete('filter');
            service.get((url), params)
                .pipe(
                    debounceTime(400),
                    distinctUntilChanged(),
                    map(res => res[jsonKey])).subscribe(data => {
                if (data) {
                    if (data.length == 1) {
                        fc.setValue(data[0]);
                    }
                }
            });
        }
    }

    replaceRegularExpCharacters(value): string {
        return value.replace(/\[/g, '\\[').replace(/\]/g, '\\]').replace(/\(/g, '\\(').replace(/\)/g, '\\)').replace('$', '\$').replace('*', '\\*');
    }

    listenAutoCompleteNewVal(fc: AbstractControl, options, dialog, component, callback, dialogCallback?: any, securityApp: any = '', message = '') {
        fc.valueChanges.subscribe(value => {
            if (typeof value === 'object' && value.value == '_NEW') {
                fc.setValue('');
                if (securityApp != '') {
                    this.checkAppSecurity(securityApp).pipe(
                        takeUntil(this.ngUnsubscribe))
                        .subscribe(res => {
                            if (res && parseInt(res.create) === 4) {
                                let dialogRef = dialog.open(component);
                                if (dialogCallback) {
                                    dialogCallback(dialogRef);
                                }
                                dialogRef.afterClosed().subscribe(data => {
                                    if (data) {
                                        // if (data.status === "S") {
                                        let option: ChoiceList = callback(data);
                                        options.push(option);
                                        fc.setValue(option);
                                        // }
                                    }
                                });
                            } else {
                                this.showAlerts('Insufficient privilage to add ' + message);
                            }
                        });
                } else {
                    let dialogRef = dialog.open(component);
                    if (dialogCallback) {
                        dialogCallback(dialogRef);
                    }
                    dialogRef.afterClosed().subscribe(data => {
                        if (data) {
                            if (data.status === 'S') {
                                let option: ChoiceList = callback(data);
                                options.push(option);
                                fc.setValue(option);
                            }
                        }
                    });
                }
            }
        });
    }

    /*
    *   Choice List helper methods
    */
    checkChoiceListSelected(options: ChoiceList[], value: string): ChoiceList {
        if (options) {
            let selected: ChoiceList;
            let filtered = options.filter(function (this, option) {
                return option.value == this;
            }, value);
            if (filtered.length > 0) {
                selected = filtered[0];
            } else {
                selected = {value: '', label: ''};
            }
            return selected;
        } else {
            return {value: '', label: ''};
        }
    }

    checkLookupsChoiceListSelected(options: any[], lookupCode: string): any {
        let selected: any;
        let filtered = options.filter(function (this, option) {
            return option.lookupCode == this;
        }, lookupCode);
        if (filtered.length > 0) {
            selected = filtered[0];
        } else {
            selected = {lookupCode: '', meaning: ''};
        }
        return selected;
    }

    checkChoiceListSelectedCommon(options: any[], value: string): any {
        let selected: ChoiceList;
        let filtered = options.filter(function (this, option) {
            return option.value == this;
        }, value);
        if (filtered.length > 0) {
            selected = filtered[0];
        } else {
            selected = {value: '', label: ''};
        }
        return selected;
    }

    checkPayRunDocChoiceListSelected(options: any[], documentNo: string): any {
        let selected: any;
        let filtered = options.filter(function (this, option) {
            return option.documentNo == this;
        }, documentNo);
        if (filtered.length > 0) {
            selected = filtered[0];
        } else {
            selected = {documentNo: '', payRunId: '', status: ''};
        }
        return selected;
    }

    resolveChoiceListForm(formData: any): any {
        let returnData = {};
        for (let key in formData) {
            const data = formData[key];
            if (data && typeof data === 'object') {
                returnData[key] = data.value ? data.value : data.value1;
            } else {
                returnData[key] = data;
            }
        }
        return returnData;
    }

    abbreviateNumber(value: number): string {
        try {
            let newValue: string = value.toString();
            if (value >= 1000) {
                let suffixes = ['', 'k', 'm', 'b', 't'];
                let suffixNum = Math.floor(('' + value).length / 3);
                let shortValue: string = '';
                for (var precision = 2; precision >= 1; precision--) {
                    shortValue = parseFloat((suffixNum != 0 ? (value / Math.pow(1000, suffixNum)) : value).toPrecision(precision)).toString();
                    var dotLessShortValue = (shortValue + '').replace(/[^a-zA-Z 0-9]+/g, '');
                    if (dotLessShortValue.length <= 2) {
                        break;
                    }
                }
                newValue = shortValue + suffixes[suffixNum];
            }
            return newValue;
        } catch (error) {
            return '';
        }
    }

    checkDocSecurity(docStatus, docTypeId): Observable<number> {
        return this.apiService.get('/trendz/document-security?docStatus=' + docStatus + '&docTypeId=' + docTypeId).pipe(
            map(data => {
                if (data && data.documentSecurity) {

                    return parseInt(data.documentSecurity.documentAccessKey);
                } else {
                    return 0;
                }
            }));
    }

    checkInitiatePermission(docTypeId): Observable<number> {
        return this.apiService.get('/trendz/initiate-permission?docTypeId=' + docTypeId).pipe(
            map(data => {
                if (data && data.permission) {
                    return parseInt(data.permission);
                } else {
                    return 0;
                }
            }));
    }

    checkAssigneeChangePermission(docTypeId): Observable<number> {
        return this.apiService.get('/trendz/change-assignee-permission?docTypeId=' + docTypeId).pipe(
            map(data => {
                if (data && data.permission) {
                    return parseInt(data.permission);
                } else {
                    return 0;
                }
            }));
    }

    checkAppSecurity(uniqueId): Observable<any> {
        return this.apiService.get('/trendz/user-permission?url=' + uniqueId).pipe(
            catchError(err => {
                return observableOf(null);
            }), map(data => {
                if (data) {
                    return data.userPermissions;
                } else {
                    return data;
                }
            }),);
    }

    checkRevisionPermission(docStatus, docTypeId): Observable<any> {
        return this.apiService.get('/trendz/document-revision?docStatus=' + docStatus + '&docTypeId=' + docTypeId).pipe(
            map(data => {
                if (data) {
                    return data.documentRevision;
                } else {
                    return data;
                }
            }));
    }

    getBpmJSON(workflowName: string, variables: WorkflowVariableModel[]) {
        return {processDefinitionKey: workflowName, variables: variables}
    }


    public static sortArray(array, sortBy, type = 'string', order = 'asc') {
        array.sort((a, b) => {
            let r = 0;
            switch (type) {
                case 'string':
                    r = a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase());
                    break;
                case 'number':
                case 'date':
                    r = a[sortBy] < b[sortBy] ? -1 : a[sortBy] > b[sortBy] ? 1 : 0;
                    break;
                default:
                    r = 0;
                    break;
            }
            if (order == 'desc') {
                r = 0 - r;
            }
            return r;
        });
    }

    /*
    *   Array Filter methods
    */
    public static filterArrayByString(mainArr, searchText, customFilter?) {
        if (!searchText) {
            if (customFilter) {
                return mainArr.filter(itemObj => {
                    return customFilter(itemObj)
                });
            } else {
                return mainArr;
            }
        }
        searchText = searchText.toLowerCase();
        return mainArr.filter(itemObj => {
            return (!customFilter || customFilter(itemObj)) && this.searchInObj(itemObj, searchText);
        });
    }

    public static searchInObj(itemObj, searchText) {

        for (const prop in itemObj) {
            if (!itemObj.hasOwnProperty(prop)) {
                continue;
            }
            const value = itemObj[prop];

            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            } else if (Array.isArray(value)) {
                if (this.searchInArray(value, searchText)) {
                    return true;
                }

            }
            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    public static searchInArray(arr, searchText) {
        for (const value of arr) {
            if (typeof value === 'string') {
                if (this.searchInString(value, searchText)) {
                    return true;
                }
            }
            if (typeof value === 'object') {
                if (this.searchInObj(value, searchText)) {
                    return true;
                }
            }
        }
    }

    //front-end sorting
    public static sortByKey(array, key) {
        return array.sort(function (a, b) {
            var x = a[key];
            var y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        });
    }

    public static searchInString(value, searchText) {
        return value.toLowerCase().includes(searchText);
    }

    public static searchStartWithString(value, searchText) {
        if (searchText.startsWith('%')) {
            let text = searchText.substring(1);
            return this.searchInString(value, text);
        } else if (searchText.includes('%')) {
            return this.searchstringWithPercentage(value, searchText)
        } else {
            return value.toLowerCase().startsWith(searchText);
        }
    }

    public static searchstringWithPercentage(value, searchText) {
        let index = searchText.indexOf('%');
        let substr1 = searchText.substring(0, index);
        let substr2 = searchText.substring(index + 1);
        if (substr2 == '') {
            return value.toLowerCase().startsWith(substr1);
        } else {
            return value.toLowerCase().startsWith(substr1) && value.substring(substr1.length).toLowerCase().includes(substr2);
        }

    }

    getCurrency(): Observable<any> {
        return this.apiService.get('/common/currency');
    }

    public getChangedProperties(formGroup: any): string[] {
        let changedProperties = [];
        Object.keys(formGroup.controls).forEach((name) => {
            let currentControl = formGroup.controls[name];
            if (currentControl.dirty) {
                changedProperties.push(name);
            }
        });
        return changedProperties;
    }

    public getAllProperties(formGroup: any): string[] {
        let properties = [];
        Object.keys(formGroup.controls).forEach((name) => {
            properties.push(name);
        });
        return properties;
    }

    public resolveFormFields(formGroup: any, dirty = false, booleanAttr = [], dateAttr = [], timeAttr = []): any {
        let json = {};
        let properties = [];
        if (dirty) {
            properties = this.getChangedProperties(formGroup);
        } else {
            Object.keys(formGroup.controls).forEach((name) => {
                properties.push(name);
            });
        }
        properties.forEach(field => {
            let data = formGroup.value[field];
            if (data && dateAttr.indexOf(field) !== -1) {
                json[field] = this.formatDate(data);
            } else if (booleanAttr.indexOf(field) !== -1) {
                json[field] = data ? 'Y' : 'N';

            } else if (data && typeof data === 'object' && !data['_isAMomentObject'] && !(data instanceof Date)) {
                json[field] = data.value ? data.value : data.value1 ? data.value1 : '';
            } else if (data && timeAttr.indexOf(field) != -1) {
                json[field] = this.formatDateTime(data ? (data['_d'] ? data['_d'] : data) : null);
            } else {
                json[field] = data;
            }
        });
        return json;
    }

    toTitleCase(str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    }

    autoCreateShortCode(toControl: AbstractControl, value, changed) {
        if (value && !changed) {
            let final = '';
            const attr = value.split(' ');
            attr.forEach(element => {
                if (element.length > 3) {
                    if (element.substring(0, 3).match(/^[A-Za-z0-9_]*$/)) {
                        final = final + (final == '' ? '' : '_') + element.substring(0, 3);
                    }
                } else {
                    if (element.match(/^[A-Za-z0-9_]*$/)) {
                        final = final + (final == '' ? '' : '_') + element;
                    }
                }
            });
            toControl.setValue(final.toUpperCase());
        } else if (!value && !changed) {
            toControl.setValue('');
        }
    }

    public isValidUrl(url): boolean {
        const allowed = this.allowedUrls.find(item => {
            return url.startsWith(item);
        });
        if (allowed) {
            return true;
        } else {
            const menu = this.appMenus;
            if (menu && menu.length > 0) {
                let keepGoing = true;
                menu.forEach(app => {
                    if (keepGoing && this.leafNodes(app, url)) {
                        keepGoing = false;
                    }
                });
                return (!keepGoing);
            } else {
                return (false);
            }
        }
    }

    leafNodes(menu, url): boolean {
        if (menu.childList.length > 0) {
            let keepGoing = true;
            menu.childList.forEach(element => {
                if (keepGoing && this.leafNodes(element, url)) {
                    keepGoing = false;
                }
            });
            return (!keepGoing);
        } else if (url.indexOf(menu.url) == 0) {
            return true;
        } else {
            return false;
        }
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    getConversionRate(currency, conversionType, conversionDate): Observable<any> {
        return this.apiService.get('/common/currency-conversion-rate?currency=' + currency + '&conversionType=' + conversionType);
    }

    showApplicationReports(taskflowKey, module, params?, origin?: ElementRef, align = 'left') {
        const event = {
            key: 'application-reports',
            position: {origin: origin, align: align},
            data: {taskflowKey: taskflowKey, module: module, params: params}
        };
        this.eventService.showGadget.next(event);
    }

    showApplicationPrograms(taskflowKey, module, params?, origin?: ElementRef, align = 'left') {
        const event = {
            key: 'application-programs',
            position: {origin: origin, align: align},
            data: {taskflowKey: taskflowKey, module: module, params: params}
        };
        this.eventService.showGadget.next(event);
    }

    checkSlabDuplicate(inputArray) {
        var seenDuplicate = false,
            testObject = {};
        inputArray.map(function (item) {
            var itemPropertyName = item['valueFrom'] + ',' + item['valueTo']
            if (itemPropertyName in testObject) {
                testObject[itemPropertyName].duplicate = true;
                item.duplicate = true;
                seenDuplicate = true;
            } else {
                testObject[itemPropertyName] = item;
                delete item.duplicate;
            }
        });
        return seenDuplicate;
    }

    get isMobile() {
        return this.breakpointObserver.isMatched('(max-width: 767px)');
    }

    displayIn12Hr(valIn24) {
        if (valIn24 && valIn24 != 'Invalid Date') {
            let datePipe = new DatePipe('en-US');
            return datePipe.transform(valIn24, 'hh:mm aa');
        }
    }

    getAppName(shortCode, applicationCode) {
        if (shortCode && applicationCode) {
            return this.apiService.get('/common/taskflow/' + shortCode + '/' + applicationCode).pipe(
                map(data => data));
        }
    }

    allowAlerts() {
        this.showSnackBar = true;
    }

    blockAlerts() {
        this.showSnackBar = false;
    }

    getLookup(lookupCode): Promise<any> {
        let params = new HttpParams();
        params = params.set('type', lookupCode);
        return new Promise<any>((resolve, reject) => {
            this.apiService.get('/lovs/lookup', params).pipe(
                map(data => data.values),
                takeUntil(this.ngUnsubscribe),)
                .subscribe(data => {
                    if (data && data.length != 0) {
                        resolve(data);
                    } else {
                        reject(false);
                    }
                });
        });
    }

    fetchLoginIframeUrl(): Promise<any> {
        let ngUnsubscribe: Subject<any> = new Subject<any>();
        return new Promise<any>((resolve, reject) => {
            this.apiService.get('/embed/default-url').pipe(
                catchError(err => {
                    reject(err)
                    return observableThrowError(null)
                }),
                map(data => data),
                takeUntil(ngUnsubscribe),)
                .subscribe(data => {
                    if (data.status == 'S') {
                        resolve(data);
                        ngUnsubscribe.next();
                        ngUnsubscribe.complete();
                    } else {
                        reject();
                        ngUnsubscribe.next();
                        ngUnsubscribe.complete();
                    }
                });
        })
    }

}
