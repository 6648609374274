import { NgModule } from '@angular/core';
import { CurrencyPipe } from 'app/shared/pipes/currency.pipe';
import { FileSizePipe } from './file-size.pipe';
import { LineBreakToBR } from "./lineBreakToBR.pipe";
import { HtmlToPlaintextPipe } from "./htmlToPlaintext.pipe";
import { LowerCasePipe } from './toLowerCase.pipe';
import { SquareBracketPipe } from './square-bracket.pipe';
import { SafePipe } from "app/shared/pipes/url-sanitizer.pipe";


@NgModule({
    declarations: [
        SquareBracketPipe,
        HtmlToPlaintextPipe,
        LineBreakToBR,
        LowerCasePipe,
        FileSizePipe,
        CurrencyPipe,
        SafePipe


    ],
    imports: [],
    exports: [
        SquareBracketPipe,
        HtmlToPlaintextPipe,
        LineBreakToBR,
        LowerCasePipe,
        FileSizePipe
    ]
})

export class TrendzPipesModule {

}
