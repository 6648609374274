import { Directive, ElementRef, HostListener } from "@angular/core";
import { NgControl } from "@angular/forms";

@Directive({
    selector: '[trendzCalculator]'
})



export class InputCalculatorDirective {

    constructor(private control: NgControl) {
    }

    get ctrl() {
        return this.control.control;
    }

    @HostListener("blur")
    onBlur() {
        if (this.control && this.ctrl && this.ctrl.value && typeof this.ctrl.value == 'string') {
            let n;
            try {
                n = eval(this.ctrl.value)
            }
            catch (e) {
            }
            if (typeof n !== 'undefined' && n !== NaN) {
                this.ctrl.setValue(n);
            }
        }
    }

}