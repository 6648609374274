import { Injectable } from '@angular/core';
import { UserService, JwtService } from 'app/shared/services';


@Injectable()
export class EmbedService {
    isEmbedded = false;
    constructor(
        private jwtService: JwtService
    ) { }

    setEmbeddedMode(): boolean {
        const url = window.location.href;
        if (url.indexOf('#/embed') > 0) {
            this.isEmbedded = true;
            let token = url.substring(url.indexOf('?token=') + 7);
            this.jwtService.saveToken(token);
        }
        return this.isEmbedded;
    }

    getUrl(url) {
        if (this.isEmbedded === true) {
            url = "/embed" + url;
        }
        return url;
    }

    sendMessage(message) {
        if (this.isEmbedded) {
            window.parent.postMessage(message, "*");
        }
    }
}