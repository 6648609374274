
import {catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { TrendzUsersService } from "app/administration/trendz/trendz-users/_services/trendz-users.service";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { UsersListConfig, ListUsers, ListRoles } from "app/administration/trendz/trendz-users/_models";

@Injectable()
export class TrendzUsersResolverService {

 constructor(
    private administrationService: TrendzUsersService,
    private router: Router
  ) {}
 resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {

this.administrationService.getAllUsers(new UsersListConfig()).subscribe(data => data);
    return this.administrationService.getAllUsers(new UsersListConfig()).pipe(
      catchError((err) => this.router.navigateByUrl('/')));
  }
}


@Injectable()
export class TrendzUserResolverService implements Resolve<ListUsers> {
    constructor(
        private service: TrendzUsersService,
        private router: Router) { }

    resolve(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<any> {
        return this.service.getUser(route.params['userId']).pipe(
            catchError((err) => this.router.navigateByUrl('/')));
    }
}
