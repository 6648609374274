import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';

@Component({
	selector: 'trendz-drive-input-modal',
	templateUrl: './input-modal.component.html'
})
export class TrendzDriveInputModalComponent {
	
	dialogTitle = "";
	input = "";
	placeHolder = "";

	constructor(private dialogRef: MatDialogRef<TrendzDriveInputModalComponent>) {
	}

	returnData() {
		this.dialogRef.close(this.input);
	}

}
