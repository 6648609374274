
import {takeUntil} from 'rxjs/operators';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subject ,  Observable } from 'rxjs';

import { ApiService, FleetApiService } from '../../services/index';
import { UtilityService } from './../../services/utility.service';


@Component({
  selector: 'p-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject<any>();
  multiple = false;
  reader = new FileReader();
  url = '';
  apiClass = '';
  fileFormat = '';
    loading = false;
  @ViewChild('fileInput') inputEl: ElementRef;
  @ViewChild('uploadedImg') image;
  fileUploaded = false;
  constructor(private utilityService: UtilityService, private dialogRef: MatDialogRef<UploadFileComponent>,
    private apiService: ApiService,
    private fleetApiService: FleetApiService) {
  }
  ngOnInit() {
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  upload(): void {
    const inputEl: HTMLInputElement = this.inputEl.nativeElement;
    const fileCount: number = inputEl.files.length;
    const formData = new FormData();
    if (fileCount > 0) {
      this.fileUploaded = true;
      for (let i = 0; i < fileCount; i++) {
        formData.append('file', inputEl.files.item(i));
      }

      if (this.apiClass == 'FLM') {
        if (this.validate()) {
          this.loading = true;
          this.fleetApiService
            .postMultipart(this.url, formData).pipe(
            takeUntil(this.ngUnsubscribe))
            .subscribe(res => {
              this.loading = false;
              if (res) {
                this.dialogRef.close(res);
              } else {
                this.utilityService.showAlerts((res && res.responseMessage) ? res.responseMessage : 'Upload operation failed');
              }
            }, err => {
              if (!err.ok) {
                this.utilityService.showAlerts(err._body ? err._body : 'Invalid file format');
              }
            });
        }
      }
      else {
        this.loading = true;
        this.apiService
          .postMultipart(this.url, formData).pipe(
          takeUntil(this.ngUnsubscribe))
          .subscribe(res => {
            this.loading = false;
            if (res) {
              this.dialogRef.close(res);
            } else {
              this.utilityService.showAlerts((res && res.responseMessage) ? res.responseMessage : 'Upload operation failed');
            }
          });
      }
    } else {
      this.utilityService.showAlerts('Failed to upload file');
    }
  }

  fileChanged(): void {
    const inputEl: HTMLInputElement = this.inputEl.nativeElement;
    const fileCount: number = inputEl.files.length;
    if (fileCount > 0) {
      this.fileUploaded = true;
    }
  }

  validate() {
    const inputEl: HTMLInputElement = this.inputEl.nativeElement;
    var _validFileExtensions = [".csv"];
    var oInput = inputEl;
    if (oInput.type == "file") {
      var sFileName = oInput.value;
      if (sFileName.length > 0) {
        var blnValid = false;
        for (var j = 0; j < _validFileExtensions.length; j++) {
          var sCurExtension = _validFileExtensions[j];
          if (sFileName.substr(sFileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) {
            blnValid = true;
            break;
          }
        }
        if (!blnValid) {
          this.utilityService.showAlerts("Invalid file format, allowed extension is: " + _validFileExtensions.join(", "));
          return false;
        }
      }
    }
    return true;
  }
}
