import { distinctUntilChanged, debounceTime, filter } from 'rxjs/operators';
import {
    AfterContentChecked,
    AfterContentInit,
    ChangeDetectorRef,
    Component,
    ContentChild,
    ContentChildren,
    Directive,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    forwardRef, HostListener,
    Inject,
    Input,
    IterableDiffers,
    OnDestroy,
    OnInit,
    Output,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material';
import { Subscription, Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';

import { ApiService, FleetApiService } from '../../services';
import { SmdPaginatorComponent } from '../smd-paginator';
import { FormControl } from '@angular/forms';
import { UtilityService } from 'app/shared/services';
import { AcmApiService } from 'app/shared/services/acm-api.service';

let columnIds = 0;

export class SmdDataRowModel {
    originalOrder?: number;

    constructor(
        public model: any,
        public checked?: boolean,
        public selected?: boolean
    ) {
    }
}

@Directive({
    selector: '[smd-data-cell]'
})
export class SmdDataTableCellComponent implements OnInit, OnDestroy {
    @Input() column: SmdDataTableColumnComponent;
    @Input() data: any;
    @Input() templ: TemplateRef<any>;

    childView: EmbeddedViewRef<SmdDataTableCellComponent>;

    constructor(
        private _viewContainer: ViewContainerRef,
        private _elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        if (this._viewContainer && this.templ) {
            this.childView = this._viewContainer.createEmbeddedView(this.templ, this);
        }
    }

    ngOnDestroy(): void {
        this.childView.destroy();
    }
}

@Component({
    selector: '[smd-datatable-row]',
    template: `
        <td *ngIf="renderCheckbox" class="smd-datatable-body-checkbox">
            <div class="smd-checkbox">
                <mat-checkbox [(ngModel)]="row.checked" (change)="_parent._onRowCheckChange(row)">
                </mat-checkbox>
            </div>
        </td>
        <td *ngFor="let column of columns"
            [class.smd-numeric-column]="column.numeric"
            [class.smd-editable]="column.editable"
            [class.smd-stretch]="column.stretch"
            [ngStyle]="column.colStyle"
        >
  <span class="smd-column-title">
  {{column.title}}
  </span>
            <span class="smd-cell-data">
  <ng-template smd-data-cell [column]="column" [data]="row.model" [templ]="column.template"></ng-template>
  <span class="smd-editable-field-placeholder"
        *ngIf="column.editable && !row.model[column.field]">{{column.editablePlaceholder}}</span>
  </span>
        </td>
    `
})
export class SmdDataTableRowComponent {
    @Input() row: SmdDataRowModel;
    @Input() renderCheckbox: boolean;
    @Input() isTitleNeeded = true;
    @Input() columns: SmdDataTableColumnComponent[];

    constructor(
        @Inject(forwardRef(() => SmdDataTable))
        private _parent: SmdDataTable,
        private dialog: MatDialog,
        private viewContainerRef: ViewContainerRef
    ) {
    }
}

@Component({
    selector: 'smd-datatable-column',
    template: `
        <ng-content select="template"></ng-content>
        <ng-template #internalTemplate *ngIf="!_customTemplate" let-model="data">
            {{getFieldValue(model)}}
        </ng-template>
    `
})
export class SmdDataTableColumnComponent implements OnInit {
    sortDir?: 'ASC' | 'DESC' = null;
    id: string = '' + ++columnIds;
    headerWidth: number = 0;

    @Input() title: string;

    @Input() titleTooltip: string;
    @Input() field: string;
    @Input() numeric: boolean = false;
    @Input() sortable: boolean = false;

    @Input() filterable: boolean = false;
    @Input() sortFn: (a: any, b: any, sortDir: string) => number;
    @Input() filterFn: (a: any, text: string) => boolean;
    @Input() editable: boolean = false;
    @Input() editablePlaceholder: string;
    @Input() stretch: boolean = false;
    @Input() colStyle = {};
    @Input() totalField;
    @Input() total;
    @Input() summable = false;
    @Input() round;
    @Input() filterOperator = 'startsWith';
    @Input() isEditable : boolean = false;

    @ContentChild(TemplateRef) _customTemplate: TemplateRef<Object>;
    @ViewChild('internalTemplate') _internalTemplate: TemplateRef<Object>;

    @Output() onFieldChange: EventEmitter<any> = new EventEmitter<any>();
    get template() {
        return this._customTemplate ? this._customTemplate : this._internalTemplate;
    }

    get hasCustomTemplate(): boolean {
        return !!this._customTemplate;
    }

    constructor(
        private _viewContainer: ViewContainerRef,
        private elementRef: ElementRef
    ) {
    }

    ngOnInit(): void {
        if (!this.title) {
            throw new Error('Title is mandatory on smd-datatable-column');
        }
        if (!this.field) {
            throw new Error('Field is mandatory on smd-datatable-column');
        }
    }

    getFieldValue(model: any) {
        return model[this.field];
    }
}

@Component({
    selector: 'smd-datatable-action-button',
    template: `
        <button mat-button
                color="primary"
                *ngIf="_checkButtonIsVisible()"
                (click)="_onButtonClick($event)">
            <span>{{label}}</span>
        </button>
    `
})
export class SmdDatatableActionButton {
    @Input() label: string;
    @Output() onClick: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        @Inject(forwardRef(() => SmdDataTable))
        private _parent: SmdDataTable
    ) {
    }

    _onButtonClick(event: Event) {
        this.onClick.emit();
    }

    _checkButtonIsVisible() {
        return this._parent.selectedRows().length == 0;
    }
}

@Component({
    selector: 'smd-datatable-contextual-button',
    template: `
        <button mat-icon-button
                *ngIf="_checkButtonIsVisible()"
                (click)="_onButtonClick($event)">
            <mat-icon>{{icon}}</mat-icon>
        </button>
    `
})
export class SmdContextualDatatableButton {
    @Input() icon: string;
    @Input() minimunSelected: number = -1;
    @Input() maxSelected: number = -1;
    @Output() onClick: EventEmitter<any[]> = new EventEmitter<any[]>();

    constructor(
        @Inject(forwardRef(() => SmdDataTable))
        private _parent: SmdDataTable
    ) {
    }

    _onButtonClick(event: Event) {
        this.onClick.emit(this._parent.selectedModels());
    }

    _checkButtonIsVisible() {
        let shouldShow = true;
        if (
            this.minimunSelected != null &&
            this.minimunSelected > 0 &&
            this._parent.selectedRows().length < this.minimunSelected
        ) {
            shouldShow = false;
        }
        if (
            shouldShow &&
            this.maxSelected > 0 &&
            this._parent.selectedRows().length > this.maxSelected
        ) {
            shouldShow = false;
        }
        return shouldShow;
    }
}

@Component({
    selector: 'smd-datatable',
    templateUrl: './datatable.component.html',
    styleUrls: ['./datatable.component.scss'],
    encapsulation: ViewEncapsulation.None,
    host: {
        '[class.smd-datatable-new-layout]': 'true',
        '[class.smd-responsive]': 'responsive',
        '[class.primary-listing]': 'primaryListing',

    }
})
export class SmdDataTable implements AfterContentInit, OnDestroy {
    countLoading: boolean = false;
    totalRow: any;
    private _allowQuery = true;
    rows: SmdDataRowModel[] = [];
    private visibleRows: SmdDataRowModel[] = [];
    private differ: any;
    private _columnsSubscription: Subscription;
    private sortDirection: string;
    private sortField: string;
    private lastQueryExecutedPage: number = 1;
    private selectedRow: any;
    private pages: number;
    private params: HttpParams = new HttpParams();
    // loading = false;
    tableHeight: number;
    private token;
    private lastSelected;
    filteredModels: any[];

    filterInput: FormControl;
    columnFilterInputs: FormControl[] = [];
    columnFilterInputsValues;

    @ViewChild(SmdPaginatorComponent) paginatorComponent: SmdPaginatorComponent;
    // @ContentChild(SmdDatatableHeader) header: SmdDatatableHeader;
    @ContentChildren(SmdDataTableColumnComponent)
    columns: QueryList<SmdDataTableColumnComponent>;

    @Input() rowCount: number = 0;
    dummyRowCount: any = 0;
    @Input() showCheckBox: boolean;
    @Input() noDataDisplayMessage: string = 'No data to display';
    @Input() noDataDisplayIcon: string = 'sentiment_very_dissatisfied';
    @Input() isTitleNeeded: boolean = true;
    @Input() models: any[] = null;
    @Input() dataUrl: string;
    @Input() countUrl: string;
    @Input() checked: boolean = false;
    @Input() paginated: boolean = true;
    @Input() paginatorRanges: number[] = [5, 10, 25, 50, 100];
    @Input() defaultRange = 10;
    @Input() ndShowDesc = false;
    @Input() ndLink = 'click here to add new item';
    @Input() showSelectedRow = false;
    @Input() responsive: boolean = false;
    @Input() dataHeader: string;
    @Input() primaryListing: boolean = false;
    @Input() selectedPage = 1;
    @Input() filterEnabled: boolean = false;
    @Input() showTableFilter: boolean = true;
    @Input() showColumnFilter: boolean = true;
    @Input() isModel = false;
    @Input() selectMultiple = true;
    @Input() preFetchPages = 20;
    @Input() bulkUpdate = false;
    @Input() preParams: HttpParams = new HttpParams();
    @Output()
    onRowChecked: EventEmitter<{
        model: any;
        checked: boolean;
    }> = new EventEmitter<{ model: any; checked: boolean }>();
    @Output() allCheckedRows: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    onRowSelected: EventEmitter<{
        model: any;
        checked: boolean;
        selected: boolean;
    }> = new EventEmitter<{ model: any; checked: boolean; selected: boolean }>();
    @Output()
    onAllRowsChecked: EventEmitter<{
        model: any;
        checked: boolean;
    }> = new EventEmitter<{ model: any; checked: boolean }>();
    @Output()
    onAllRowsSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() dataChange: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    pageChange: EventEmitter<any> = new EventEmitter<{ page: number }>();
    @Output() newItemClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() selectPage: boolean = false;
    @Input() loading: boolean = false;
    @Output() checkedPksNew: EventEmitter<any> = new EventEmitter<any>();
    @Output() filterParams: EventEmitter<any> = new EventEmitter<any>();

    @Input() apiClass: any;
    @Input() apiMethod = 'GET';
    @Input() postBody: any = {};
    @Input() searchDB = true;
    @Input() preFilters: any = {};
    @Input() primaryKey: any;
    customFilter: any;
    customFilters: any;
    checkedRows: any = [];
    checkedPKs: any = [];
    @Input() inputCheckedPKs: any = [];
    @Input() removeVariable = false;
    @Output() range: EventEmitter<any> = new EventEmitter<any>();
    selectedRowCount: number = 0;
    @Input() showTotal: boolean = false;
    @Output() editColumnValues: EventEmitter<any> = new EventEmitter<any>();
    constructor(
        differs: IterableDiffers,
        private _viewContainer: ViewContainerRef,
        public changeDetector: ChangeDetectorRef,
        private apiService: ApiService,
        private FLMapiService: FleetApiService,
        private acmApiService: AcmApiService
    ) {
        this.differ = differs.find([]).create(null);
        this.filterInput = new FormControl('');
    }

    ngAfterContentInit() {
        if (!this.apiClass) {
            this.apiClass = this.apiService;
        }
        if (!this.dataHeader) {
            this.dataHeader = 'persons';
        }

        if (!this.paginatorComponent.selectedRange) {
            this.paginatorComponent.selectedRange = this.defaultRange;
        }

        if (!this.models) {
            this._queryTableData().then(() => {
            }, () => {
            });
        } else {
            this._updateRows();
            if (this.showTotal) {
                this.setTotalRow();
            }
        }
        this._columnsSubscription = this.columns.changes.subscribe(() => {
            this._updateRows();
            this.changeDetector.markForCheck();
        });

        if (this.removeVariable == true) {
            this.checkedPKs = this.inputCheckedPKs;
            this._updateVisibleRows();
        }

        this.filterInput.valueChanges.pipe(
            debounceTime(400),
            distinctUntilChanged())
            .subscribe(searchText => {
                if (this.paginatorComponent) {
                    this.paginatorComponent.reset();
                }
                this._queryTableData().then(() => {
                }, () => {
                });
            });

        this.columns.forEach(column => {
            if (column.filterable) {
                this.columnFilterInputs[column.id] = new FormControl(
                    this.columnFilterInputsValues
                        ? this.columnFilterInputsValues[column.field]
                            ? this.columnFilterInputsValues[column.field]
                            : ''
                        : ''
                );
                this.columnFilterInputs[column.id].valueChanges.pipe(
                    debounceTime(400),
                    distinctUntilChanged()).subscribe(val => {
                    if (this.paginatorComponent) {
                        this.paginatorComponent.reset();
                    }
                    this._queryTableData().then(() => {
                    }, () => {
                    });
                });
            }
        });
    }

    reinitialize() {
        this.ngAfterContentInit();
        this.setTableDimension();
    }

    rangeChanged(selectedRange) {
        this.range.emit(selectedRange);
        this.setTableDimension();
    }

    ngOnDestroy(): void {
        if (this._columnsSubscription) {
            this._columnsSubscription.unsubscribe();
        }
    }

    newRecord($event) {
        this.newItemClicked.emit({clicked: true});
    }

    _updateRows() {
        if (this.models) {
            this.rows.length = 0;
            try {
                this.models.forEach(
                    (model: any, index: number) =>
                        (this.rows[index] = new SmdDataRowModel(model, false))
                );
                this.rows.forEach((row, index) => (row.originalOrder = index));
                this._setSelectedRows();
                this._updateVisibleRows();
                this.setTableDimension();
            } catch (error) {
            }
        }
    }

    _updateRowsFromList(models) {
        if (models) {
            this.rows.length = 0;
            try {
                models.forEach(
                    (model: any, index: number) =>
                        (this.rows[index] = new SmdDataRowModel(model, false))
                );
                this.rows.forEach((row, index) => (row.originalOrder = index));
                this._setSelectedRows();
                this._updateVisibleRows();
            } catch (error) {
            }
        }
    }

    _matches(
        row: SmdDataRowModel,
        columns: SmdDataTableColumnComponent[],
        text: string
    ): boolean {
        if (isNullOrUndefined(text) || text.trim() == '') {
            return true;
        }

        let subtexts: string[] = text.trim().split(' ');
        for (let subtext of subtexts) {
            for (let column of columns) {
                // let filterFn = this._filterValue;
                let value = column.getFieldValue(row.model);
                if (column.hasCustomTemplate) {
                    value = row.model;
                    // filterFn = column.filterFn ? column.filterFn : (value: any, text: string) => false;
                }
                // if (filterFn(value, subtext)) {
                //   return true;
                // }
            }
        }
        return false;
    }

    // private _filterValue(value: any, text: string): boolean {
    //   return value && value.toString().toUpperCase().indexOf(text.toString().toUpperCase()) > -1;
    // }

    selectedRows(): SmdDataRowModel[] {
        return this.rows.filter(row => row.checked);
    }

    selectedModels(): any[] {
        return this.selectedRows().map(row => row.model);
    }

    _selectedModels(): Observable<any>[] {
        return this.selectedRows().map(row => row.model);
    }

    _onMasterCheckChange() {
        if (this.selectPage == true) {
            this.visibleRows.forEach((row: SmdDataRowModel) => {
                if (row.checked != this.checked) {
                    row.checked = this.checked;
                    this.selectedRowCountFn(row);
                }
            });
        } else {
            this.rows.forEach((row: SmdDataRowModel) => {
                if (row.checked != this.checked) {
                    row.checked = this.checked;
                    this.selectedRowCountFn(row);
                }
            });
        }
        this.onAllRowsSelected.emit(this.checked);
        this.onAllRowsChecked.emit({
            model: this.visibleRows,
            checked: this.checked
        });
    }

    selectedRowCountFn(row) {
        if (!this.bulkUpdate) {
            if (this.primaryKey) {
                let length = this.primaryKey.length;
                let pK = '';
                this.primaryKey.forEach((primKey, i) => {
                    pK = pK + ',' + row.model[primKey].toString();
                });
                var idx = this.checkedPKs.indexOf(pK);
                if (idx != -1) {
                    this.selectedRowCount--;
                    this.checkedPKs.splice(idx, 1);
                    this.checkedRows.splice(idx, 1);
                    let checkedArr = {
                        checkedPks: this.checkedPKs,
                        checkedList: this.checkedRows
                    };
                    this.checkedPksNew.emit({checkedArr: checkedArr});
                }
                if (row.checked) {
                    this.selectedRowCount++;
                    this.checkedRows.push(row.model);
                    this.checkedPKs.push(pK);
                    let checkedArr = {
                        checkedPks: this.checkedPKs,
                        checkedList: this.checkedRows
                    };
                    this.checkedPksNew.emit({checkedArr: checkedArr});
                }
            }
        } else {
            this.bulkUpdateCount(row);
        }
    }

    bulkUpdateCount(row) {
        let checkedRows = this.rows.filter(row => row.checked);
        this.selectedRowCount = checkedRows.length;
        this.checkedPksNew.emit({
            checkedArr: checkedRows.map(row => row.model[this.primaryKey])
        });
    }

    _onRowCheckChange(row: SmdDataRowModel) {
        if (this.selectMultiple) {
            let isMasterChecked = this.checked;
            this.selectedRowCountFn(row);
            let checkedRows = [];
            this.rows
                .filter(row => row.checked == true)
                .forEach(row => checkedRows.push(row.model));
            this.allCheckedRows.emit(checkedRows);
            if (row.checked) {
                if (this.rows.filter(row => row.checked).length == this.rows.length) {
                    this.checked = true;
                }
            } else {
                if (this.checked) {
                    this.checked = false;
                }
            }
            this.onRowSelected.emit({
                model: row.model,
                checked: row.checked,
                selected: false
            });
            this.onRowChecked.emit({
                model: row.model,
                checked: row.checked
            });

            if (this.checked != isMasterChecked) {
                this.onAllRowsSelected.emit(this.checked);
            }

            if (this.checked != isMasterChecked) {
                this.onAllRowsChecked.emit({
                    model: this.visibleRows,
                    checked: this.checked
                });
            }
        } else {
            if (row.checked && this.lastSelected && this.lastSelected.checked) {
                this.lastSelected.checked = false;
                this.lastSelected = row;
            }
            let isMasterChecked = this.checked;
            this.selectedRowCountFn(row);
            let checkedRows = [];
            this.rows
                .filter(row => row.checked == true)
                .forEach(row => checkedRows.push(row.model));
            this.allCheckedRows.emit(checkedRows);
            if (row.checked) {
                if (this.rows.filter(row => row.checked).length == this.rows.length) {
                    this.checked = true;
                }
            } else {
                if (this.checked) {
                    this.checked = false;
                }
            }
            this.onRowSelected.emit({
                model: row.model,
                checked: row.checked,
                selected: false
            });
            this.onRowChecked.emit({
                model: row.model,
                checked: row.checked
            });

            if (this.checked != isMasterChecked) {
                this.onAllRowsSelected.emit(this.checked);
            }

            if (this.checked != isMasterChecked) {
                this.onAllRowsChecked.emit({
                    model: this.visibleRows,
                    checked: this.checked
                });
            }
            if (!this.lastSelected) {
                this.lastSelected = row;
            }
        }
    }

    _onRowSelection(row: SmdDataRowModel, selected: false) {
        if (this.showSelectedRow) {
            if (this.selectedRow) {
                this.selectedRow.selected = false;
            }
            row.selected = true;
            this.selectedRow = row;
        }
        this.onRowSelected.emit({
            model: row.model,
            checked: row.checked,
            selected: selected
        });
    }

    _onFilter(event: any): void {
        this.paginatorComponent.reset();
        this._updateRows();
    }

    _sortColumn(column: SmdDataTableColumnComponent) {
        if (this.dataHeader && this.dataUrl) {
            if (column.sortable) {
                this.columns
                    .filter(col => col.id != column.id)
                    .forEach(col => (col.sortDir = null));
                if (!column.sortDir) {
                    column.sortDir = 'ASC';
                } else {
                    column.sortDir = column.sortDir == 'ASC' ? 'DESC' : null;
                }
                this.sortDirection = column.sortDir;
                this.sortField = column.field;
                this._queryTableData().then(() => {
                }, () => {
                });
            }
        } else {
            // front-end sorting
            let itrateModel = [];
            if (!this.filteredModels) {
                itrateModel = this.models;
            } else {
                itrateModel = this.filteredModels;
            }
            let sortedModel = [];
            sortedModel = UtilityService.sortByKey(itrateModel, column.field);
            if (!column.sortDir) {
                column.sortDir = 'ASC';
                this._updateRowsFromList(sortedModel);
            } else if (column.sortDir == 'ASC') {
                column.sortDir = 'DESC';
                this._updateRowsFromList(sortedModel.reverse());
            } else {
                column.sortDir = null;
                this._updateRowsFromList(sortedModel);
            }
        }
    }

    _sortRows(a: any, b: any, sortDir: string = 'ASC') {
        let dir = sortDir == 'ASC' ? 1 : -1;
        if (a > b) {
            return 1 * dir;
        }
        if (a < b) {
            return -1 * dir;
        }
        return 0;
    }

    _onPageChange() {
        try {
            this.preFetchPages = Math.max(200, this.models.length) / this.paginatorComponent.selectedRange;
            
        }catch (e) {
            this.preFetchPages = 20;
        }    
        if (
            this.paginatorComponent.currentPage.page < this.lastQueryExecutedPage ||
            this.paginatorComponent.currentPage.page >=
            this.lastQueryExecutedPage + this.preFetchPages
        ) {
            this._queryTableData().then(
                () => {
                    this._updateVisibleRows();
                    this.pageChange.emit({
                        page: this.paginatorComponent.currentPage.page
                    });
                },
                () => {
                }
            );
        } else {
            this._updateVisibleRows();
            this.setTableDimension();
            this.pageChange.emit({page: this.paginatorComponent.currentPage.page});
        }
    }

    _columnTemplates() {
        return this.columns.toArray().map(c => c.template);
    }

    _onCustomSearch(params: HttpParams): void {
        if (params.get('filter')) {
            if (params.get('advancedFilter') && params.get('advancedFilter') != '') {
                let filter = {
                    type: 'group',
                    con: 'and',
                    items: JSON.parse(params.get('filter'))
                };
                this.customFilter = {
                    type: 'group',
                    con: 'and',
                    items: [filter, params.get('advancedFilter')]
                };
            } else {
                this.customFilter = {
                    type: 'group',
                    con: 'and',
                    items: JSON.parse(params.get('filter'))
                };
            }
        } else if (
            params.get('advancedFilter') &&
            params.get('advancedFilter') != ''
        ) {
            this.customFilter = params.get('advancedFilter');
        } else {
            this.customFilter = null;
        }
        this.customFilters = params.get('filters')
            ? JSON.parse(params.get('filters'))
            : null;
        this.params = params;
        this.paginatorComponent.reset();
        if (
            !(
                this.paginatorComponent.currentPage.page < this.lastQueryExecutedPage ||
                this.paginatorComponent.currentPage.page >=
                this.lastQueryExecutedPage + this.preFetchPages
            )
        ) {
            this._queryTableData().then(
                () => {
                    this._updateVisibleRows();
                },
                () => {
                }
            );
        }
    }

    public refresh(model: any[] = null) {
        if (model) {
            this.models = model;
            if (
                (this.dataHeader && this.dataUrl) ||
                !this.filterEnabled ||
                !this.showColumnFilter
            ) {
                this.setTotalRow();
                this._updateRows();
               this.paginatorComponent.reset();
            } else {
                this._queryTableData().then(() => {
                    this.setTableDimension();
                }, (err) => {
                    this.setTableDimension();
                });
            }
        } else {
            this._queryTableData().then(() => {
                this.setTableDimension();
            }, () => {
                this.setTableDimension();
            });
        }
    }

    public filterTable() {
        if (this.paginatorComponent) {
            this.paginatorComponent.reset();
            this.selectedPage = 1;
        }
        this._queryTableData().then(() => {
        }, () => {
        });
        // this.showColumnFilter = !this.showColumnFilter;
    }

    private _queryTableData(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._queryTableDataFn().then(() => {
                this.setTableDimension();
                resolve();
            }, (err) => {
                this.setTableDimension();
                reject(err);
            })
        })
    }

    private _queryTableDataFn(): Promise<any> {        
        return new Promise((resolve, reject) => {
            this.loading = true;
            const size = this.paginatorComponent.currentPage.size
                ? this.paginatorComponent.currentPage.size
                : this.defaultRange;
            let page: number =
                this.paginatorComponent.currentPage.page - this.preFetchPages / 2 <= 0
                    ? 1
                    : Math.round(
                        this.paginatorComponent.currentPage.page - this.preFetchPages / 2
                    );
            let offset = (page - 1) * size + 1;
            // let offset = 1;
            let limit: number = this.preFetchPages * size;
            if (this.apiMethod == 'GET') {
                this.params = this.params.set('offset', '' + offset);
                this.params = this.params.set('limit', '' + limit);
                if (this.sortDirection != null) {
                    this.params = this.params.set('sort', '' + this.sortField);
                    this.params = this.params.set('direction', '' + this.sortDirection);
                } else {
                    this.params = this.params.delete('sort');
                    this.params = this.params.delete('direction');
                }
                this.preParams.keys().forEach(key => {
                    this.params = this.params.set(key, this.preParams.get(key));
                });
            } else if (this.apiMethod == 'POST') {
                this.postBody.offset = offset;
                this.postBody.limit = limit;
                if (this.sortDirection != null) {
                    this.postBody.sort = this.sortField;
                    this.postBody.direction = this.sortDirection;
                } else {
                    if (this.postBody.sort) {
                        delete this.postBody.sort;
                    }
                    if (this.postBody.direction) {
                        delete this.postBody.direction;
                    }
                }
            }

            /**
             * Filter conditions START
             */
            let filter = this.customFilter ? [this.customFilter] : [];
            let andFilters = {};
            let filters = this.customFilters ? this.customFilters : {};
            if (this.filterEnabled) {
                let andGrp = {
                    type: 'group',
                    con: 'and',
                    items: []
                };
                let orGrp = {
                    type: 'group',
                    con: 'and',
                    items: []
                };
                this.columns.forEach(column => {
                    if (column.filterable) {
                        if (
                            this.columnFilterInputs[column.id] &&
                            this.columnFilterInputs[column.id].value
                        ) {
                            andGrp.items.push({
                                type: 'item',
                                con: 'and',
                                operator: column.filterOperator,
                                attr: column.field,
                                value: this.columnFilterInputs[column.id].value.toLowerCase()
                            });
                            andFilters[column.field] = this.columnFilterInputs[
                                column.id
                                ].value.toLowerCase();
                        }
                        if (column.filterable && this.filterInput.value) {
                            orGrp.items.push({
                                type: 'item',
                                con: 'or',
                                operator: column.filterOperator,
                                attr: column.field,
                                value: this.filterInput.value.toLowerCase()
                            });
                            filters[column.field] = this.filterInput.value.toLowerCase();
                        }
                    }
                });
                if (andGrp.items.length > 0) {
                    filter.push(andGrp);
                }
                if (orGrp.items.length > 0) {
                    filter.push(orGrp);
                }
                this.filterParams.emit(andFilters);
            }
            for (let key of Object.keys(this.preFilters)) {
                andFilters[key] = this.preFilters[key] + '<?EQ>';
            }
            if (this.apiMethod == 'GET') {
                this.params = this.params.set('filter', JSON.stringify(filter));
                this.params = this.params.set('filters', JSON.stringify(filters));
                this.params = this.params.set('andFilters', JSON.stringify(andFilters));
            } else if (this.apiMethod == 'POST') {
                this.postBody.filter = filter;
                this.postBody.filters = filters;
                this.postBody.andFilters = andFilters;
            }
            /**
             * Filter conditions END
             */
            // --- HERE
            if (this.dataHeader && this.dataUrl) {
                this.token = Math.random();
                if (this.apiMethod == 'GET') {
                    this.apiClass
                        .get('/' + this.dataUrl, this.params, this.token)
                        .subscribe(
                            data => {
                                if (this.showTotal) {
                                    this.setTotalRow(data);
                                }
                                if (data.status && typeof data.status === 'number') {
                                    if (data.status == 200) {
                                        data = data.data;
                                    } else {
                                    reject();
                                    }
                                }
                                if (this.token == data.token) {
                                    this.lastQueryExecutedPage = page;
                                    this.models = data[this.dataHeader];
                                    if (data.count) {
                                        this.rowCount = data.count;
                                    } else if (data[this.dataHeader] && this.countLoading) {
                                        if (data[this.dataHeader].length < limit) {
                                            this.rowCount = data[this.dataHeader].length;
                                            this.dummyRowCount = data[this.dataHeader].length;
                                        } else {
                                            this.dummyRowCount =
                                                data[this.dataHeader].length - 1 + '+';
                                            this.rowCount = data[this.dataHeader].length - 1;
                                        }
                                    }
                                    this._updateRows();
                                    this.checkedPKs = this.inputCheckedPKs;
                                    this._updateVisibleRows();
                                    this.dataChange.emit({
                                        offset: offset,
                                        limit: limit,
                                        data: data,
                                        columnFilterValues: this.getColumnFilterInputValues(),
                                        params: this.params,
                                        rowCount: this.dummyRowCount
                                    });
                                    this.loading = false;
                                    resolve();
                                } else {
                                    this.loading = false;
                                    reject();
                                }
                            },
                            err => {
                                this.loading = false;
                                reject();
                            }
                        );
                    if (this.countUrl) {
                        // count
                        this.countLoading = true;
                        this.apiClass
                            .get('/' + this.countUrl, this.params, this.token)
                            .subscribe(
                                data => {
                                    if (this.token == data.token) {
                                        if (data.count) {
                                            this.rowCount = data.count;
                                            this.dataChange.emit({
                                                rowCount: data.count
                                            });
                                            delete this.dummyRowCount;
                                        }
                                        resolve();
                                    } else {
                                        reject();
                                    }
                                    this.countLoading = false;
                                },
                                err => {
                                    this.countLoading = false;
                                    reject();
                                }
                            );
                        // count
                    }
                } else if (this.apiMethod == 'POST') {
                    this.apiClass
                        .post('/' + this.dataUrl, this.postBody, this.token)
                        .subscribe(
                            data => {
                                if (data.status && typeof data.status === 'number') {
                                    if (data.status == 200) {
                                        data = data.data;
                                    } else {
                                        reject();
                                    }
                                }
                                if (this.token == data.token) {
                                    this.lastQueryExecutedPage = page;
                                    this.models = data[this.dataHeader];
                                    this.rowCount = data.count;
                                    this._updateRows();
                                    this.dataChange.emit({
                                        offset: offset,
                                        limit: limit,
                                        data: data,
                                        columnFilterValues: this.getColumnFilterInputValues()
                                    });
                                    resolve();
                                } else {
                                    reject();
                                }
                                this.loading = false;
                            },
                            err => {
                                this.loading = false;
                                reject();
                            }
                        );
                }
            } else {
                if (this.models) {
                    //Searching from front - end
                    this.filteredModels = JSON.parse(JSON.stringify(this.models));
                    let tempModels = [];
                    this.loading = true;
                    if (this.columns) {
                        this.columns.forEach((column, index) => {
                            if (column.filterable) {
                                if (
                                    this.columnFilterInputs[column.id] &&
                                    this.columnFilterInputs[column.id].value
                                ) {
                                    let value = this.columnFilterInputs[column.id].value;
                                    value = value.toLowerCase();
                                    let field = column.field;
                                    this.filteredModels.forEach((model, index) => {
                                        if (
                                            model.hasOwnProperty('unfiltered') &&
                                            model.unfiltered == true
                                        ) {
                                            tempModels.push(model);
                                        } else if (model.hasOwnProperty(field)) {
                                            if (
                                                typeof model[field] == 'object' &&
                                                UtilityService.searchInObj(model[field], value)
                                            ) {
                                                tempModels.push(model);
                                            } else if (
                                                typeof model[field] == 'string' &&
                                                UtilityService.searchStartWithString(model[field], value)
                                            ) {
                                                tempModels.push(model);
                                            } else if (
                                                Array.isArray(model[field]) &&
                                                UtilityService.searchInArray(model[field], value)
                                            ) {
                                                tempModels.push(model);
                                            } else if (
                                                typeof model[field] == 'number' &&
                                                UtilityService.searchInString(
                                                    model[field].toString(),
                                                    value
                                                )
                                            ) {
                                                tempModels.push(model);
                                            }
                                        }
                                    });
                                    this.filteredModels = JSON.parse(JSON.stringify(tempModels));
                                    tempModels = [];
                                }
                            }
                        });
                    }
                    this._updateRowsFromList(this.filteredModels);
                    this.rowCount = this.filteredModels.length;

                    this.dataChange.emit({rowCount: this.rowCount});
                    this.loading = false;
                    this.setTotalRow();
                    resolve();
                } else {
                    this.loading = false;
                    reject();
                }
            }
        });
    }

    public clearAllFilters() {
        if (this.columns) {
            this.columns.forEach(column => {
                if (
                    this.columnFilterInputs[column.id] &&
                    this.columnFilterInputs[column.id].value
                ) {
                    this.columnFilterInputs[column.id].setValue('');
                }
            });
        }
    }

    public resetPage() {
        this.paginatorComponent.reset();
    }

    public setColumnFilterInputValues(columnFilterInputValues) {
        this.columnFilterInputsValues = columnFilterInputValues;
    }

    public getColumnFilterInputValues() {
        let values: any[] = [];
        if (this.columns) {
            this.columns.forEach(column => {
                if (
                    this.columnFilterInputs[column.id] &&
                    this.columnFilterInputs[column.id].value
                ) {
                    values[column.field] = this.columnFilterInputs[column.id].value;
                }
            });
        }
        return values;
    }

    private _updateVisibleRows() {
        if (this.paginated) {
            this.visibleRows = this.rows.filter(
                (value: SmdDataRowModel, index: number) =>
                    this.paginatorComponent.currentPage.isInsidePage(
                        index +
                        (this.lastQueryExecutedPage - 1) *
                        this.paginatorComponent.currentPage.size
                    )
            );
        } else {
            this.visibleRows = this.rows;
        }
        this._setHeaderCheckBox();
        if (this.primaryKey) {
            this.visibleRows.forEach(row => {
                let pK = '';
                this.primaryKey.forEach((primKey, i) => {
                    pK = pK + ',' + row.model[this.primaryKey[i]].toString();
                });
                if (this.checkedPKs.indexOf(pK) != -1) {
                    row.checked = true;
                }
            });
        }
        this.selectedRowCount = this.checkedPKs.length;
    }

    private _setSelectedRows() {
        if (this.checked) {
            this.visibleRows.forEach((row: SmdDataRowModel) => {
                row.checked = true;
            });
        }
    }

    private _setHeaderCheckBox() {
        let flag = false;
        if (this.visibleRows && this.visibleRows.length > 0) {
            flag = true;
        }
        this.visibleRows.forEach((row: SmdDataRowModel) => {
            if (!row.checked) {
                flag = false;
            }
        });
        this.checked = flag;
    }

    _shouldRenderCheckbox() {
        return this.showCheckBox;
    }

    setColumnFiltersExplicitly(columnFilters: any[]) {
        this._allowQuery = false;
        const keys: string[] = Object.keys(columnFilters);
        const filters = keys.map(key => {
            return {
                field: key,
                value: columnFilters[key]
            };
        });
        filters.forEach(filter => {
            const id: string = this.getIdOfColumns(filter.field);
            this.columnFilterInputs[id].setValue(filter.value);
        });
        this._allowQuery = true;
    }

    getIdOfColumns(field: string): string {
        return this.columns
            .toArray()
            .filter(column => {
                return field === column.field;
            })
            .map(column => {
                return column.id;
            })[0];
    }

    getParams() {
        return this.params;
    }

    setTotalRow(data?) {
        this.totalRow = [];
        if (this.columns) {
            let i = this.showCheckBox ? 1 : 0;
            this.columns.forEach(column => {
                this.totalRow[i] = '';
                if (data && column.totalField) {
                    this.totalRow[i] = data[column.totalField];
                } else if (column.total) {
                    this.totalRow[i] = column.total;
                } else if (column.summable) {
                    if (this.models && !this.filteredModels) {
                        this.filteredModels = this.models;
                    }
                    if (this.filteredModels) {
                        let total = 0;
                        this.filteredModels.forEach(model => {
                            let value = model[column.field];
                            if (isNaN(value)) {
                                value = value.replace(',', '');
                            }
                            if (value && !isNaN(value)) {
                                total += Number(value);
                            }
                        });
                        this.totalRow[i] = total;
                    }
                }
                if (column.round) {
                    let total = this.totalRow[i];
                    if (!isNaN(total) && !isNaN(column.round)) {
                        total = Number(total).toFixed(column.round);
                    }
                    this.totalRow[i] = total;
                }

                i++;
            });
            this.totalRow[0] = 'Total';
        }
    }

    findIndexFromField(field) {
        let i = 0;
        this.columns.forEach((column, index) => {
            if (column.field == field) {
                i = index;
            }
        });
        return i + 1;
    }

    getTotal(field) {
        let i = this.findIndexFromField(field);
        return this.totalRow[i];
    }

    setTotal(field, value) {
        let i = this.findIndexFromField(field);
        this.totalRow[i] = value;
    }

    public refreshFilterModels(data) {
        let tempModels = this.models;
        this.refresh(data);
        this.models = tempModels;
    }
    _editColumnData(column: SmdDataTableColumnComponent) {
        if(column.isEditable){
        this.editColumnValues.emit(column);
        }
    }

    @HostListener('window:resize')
    setTableDimension() {
        setTimeout(() => {
            if (this.primaryListing) {
                const domTable = this._viewContainer.element.nativeElement;
                const tr = domTable.querySelectorAll('.smd-table-body > table > tbody > tr')[0];
                const tableHeader = domTable.querySelectorAll('table.smd-data-table > thead')[0];
                const headerHeight = (tableHeader ? tableHeader.offsetHeight : 74) + 4;
                const rowsHeight = (this.visibleRows.length ? (this.visibleRows.length * (tr ? tr.offsetHeight : 0)) : 8) + headerHeight;
                const viewHeight =
                    domTable.offsetHeight -
                    (this.paginated ? this.paginatorComponent.nativeElement.nativeElement.offsetHeight + 20 : 16);
                this.tableHeight = viewHeight < rowsHeight ? viewHeight : rowsHeight;
                const tds = tr ? tr.querySelectorAll('td') : null;
                this.columns.forEach((column: SmdDataTableColumnComponent, index) => {
                    if (this.paginated) {
                        index++;
                    }
                    if (tds && tds[index]) {
                        column.headerWidth = tds[index].offsetWidth - (column.title == ' ' ? 24 : 20);
                    }
                });
            }
        }, 0)
    }
}
