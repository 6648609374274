import { Directive, HostBinding, Input, OnChanges } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { DomSanitizer } from '@angular/platform-browser';

import { ApiService } from 'app/shared/services';

@Directive({
    selector: '[driveThumbnail]',
})
export class ThumbnailDirective implements OnChanges {
    @HostBinding('src') src;
    @Input() imageUrl: string;
    @Input() errorUrl = 'assets/images/avatars/user.jpg';
    constructor(private http: HttpClient
        , private sanitizer: DomSanitizer
        , private apiService: ApiService) {

    }

    ngOnChanges() {
        this.src = this.sanitizer.bypassSecurityTrustUrl(this.errorUrl);
        this.apiService.getImage(this.imageUrl)
            .subscribe(data => {
                this.src = this.sanitizer.bypassSecurityTrustUrl('data:image/jpeg;base64,' + data.text());
            },
            err => {
                this.src = this.sanitizer.bypassSecurityTrustUrl(this.errorUrl);
            });
    }
}
