
import {interval as observableInterval,  Subscription, Observable } from 'rxjs';
import { Component, OnDestroy, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

import { User } from '../models';
import { ApiService, EventService, UserService, TrendzMessageService, UtilityService } from '../services';
import { BPMUtilsUserActions } from 'app/shared/bpmutils/bpmutils-user-actions.service';
// import { BPMUtils } from 'app/shared/bpmutils';
import { EmployeeUserLinkComponent } from 'app/shared/layout/employee-user-link/employee-user-link.component';
import { MatDialog } from '@angular/material';

@Component({
	selector: 'app-header-right',
	templateUrl: './header-right.component.html',
	styleUrls: ['./header-right.component.scss']
})
export class HeaderRightComponent implements OnInit, OnDestroy {

	personnelNum: any;
	currentUser: User;
	private userSubs: Subscription;

	pendingNotificationCount = 0;
	private onNewNotification: Subscription;
	private onPendingNotificationCountChanged: Subscription;
	private fetchingNotification = false;

	fetchingEmployeeLink = true;
	employeeLinkStatus = -1;

	@ViewChild("downloadsBtn") downloadsBtn: ElementRef;
	@ViewChild("notificationsBtn") notificationsBtn: ElementRef;
	@ViewChild("usermenuBtn") usermenuBtn: ElementRef;
	@ViewChild("settingsBtn") settingsBtn: ElementRef;

	constructor(
		private userService: UserService,
		private apiService: ApiService,
		private eventService: EventService,
		private trendzMessageService: TrendzMessageService,
		private utilityService: UtilityService,
		// private bpmUtils: BPMUtils,
		private dialog: MatDialog,
	) { }

	ngOnInit() {
			this.userSubs = this.userService.currentUser.subscribe(
				(userData) => {
					this.currentUser = userData;
				}
			);
		this.initNotificationCheck();

		this.onPendingNotificationCountChanged = this.eventService.onPendingNotificationCountChanged.subscribe(change => {
			if (change) {
				if (change == 'ALL') {
					this.pendingNotificationCount = 0;
				}
				else {
					this.pendingNotificationCount += change;
				}
			}
		});

		this.apiService.get('/trendz/users/' + this.currentUser.userId + '/employee-link-status').subscribe(data => {
			this.employeeLinkStatus = data.emmployeeLinkStatus;
			this.fetchingEmployeeLink = false;
		}, err => { });
	}

	ngOnDestroy() {
		if (this.userSubs) {
			this.userSubs.unsubscribe();
		}
		if (this.onNewNotification) {
			this.onNewNotification.unsubscribe();
		}
		if (this.onPendingNotificationCountChanged) {
			this.onPendingNotificationCountChanged.unsubscribe();
		}
		if (this.trendzMessageService.checkNotification) {
			this.trendzMessageService.checkNotification.unsubscribe();
		}
	}

	initNotificationCheck() {
		
		this.getNewNotifications();
		
		if(this.trendzMessageService.checkNotification) {
			this.trendzMessageService.checkNotification.unsubscribe();
		}
		this.trendzMessageService.checkNotification = observableInterval(40000).subscribe(x => {
			this.getNewNotifications();
		});

		if (this.onNewNotification) {
			this.onNewNotification.unsubscribe();
		}
		this.onNewNotification = this.eventService.onNewNotification.subscribe(change => {
			if (change == 1) {
				this.getNewNotifications();
			}
		});

	}

	getNewNotifications() {
		if (this.currentUser.userId) {
			if (!this.fetchingNotification) {
				this.fetchingNotification = true;
				this.trendzMessageService.getNewNotifications().then(response => {
					if (response) {
						if (response.status == 200) {
							this.pendingNotificationCount = response.data.pendingCount;
							if (response.data.count > 3) {
								this.utilityService.showAlerts("You have " + response.data.count + " new notifications.");
							}
							else {
								for (let notification of response.data.notifications) {
									let tmp = document.implementation.createHTMLDocument("New").body;
									tmp.innerHTML = notification.notification;
									this.utilityService.showAlerts(tmp.textContent || tmp.innerText || "");
								}
							}
							if (response.data.count > 0) {
								let subscriptionIds: any = { isNotified: 1, subscriptionIds: [] };
								for (let notification of response.data.notifications) {
									subscriptionIds.subscriptionIds.push({ subscriptionId: notification.subscriptionId });
								}
								this.trendzMessageService.updateNotifiedAll(subscriptionIds);
							}
						} else if (response.status == 401) {
							this.utilityService.showAlerts("Authentication has been failed");
							this.userService.purgeAuth();
						}
					}
					this.fetchingNotification = false;
				}, () => {
					this.fetchingNotification = false;
				});
			}
		}
	}

	closeGadget() {
		this.eventService.showGadget.next(false);
	}

	showNotifications() {
		const event = { key: 'notifications', position: { origin: this.notificationsBtn, align: 'right' } };
		this.eventService.showGadget.next(event);
	}

	showDownloads() {
		const event = { key: 'downloads', position: { origin: this.downloadsBtn, align: 'right' } };
		this.eventService.showGadget.next(event);
	}

	showUserMenu() {
		const event = { key: 'usermenu', position: { origin: this.usermenuBtn, align: 'right' } };
		this.eventService.showGadget.next(event);
	}

	showUserSettings() {
		const event = { key: 'usersettings', position: { origin: this.settingsBtn, align: 'right' } };
		this.eventService.showGadget.next(event);
	}

	showEmployeeLinking() {
		if (!this.fetchingEmployeeLink && this.employeeLinkStatus == -1){
			let dialogRef = this.dialog.open(EmployeeUserLinkComponent);
			dialogRef.afterClosed().subscribe(result => {
				if (result) {
					this.employeeLinkStatus = 1;
				}
			});
		}
	}
}
