
import {takeUntil, catchError} from 'rxjs/operators';
import { Component} from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';

import { UserService, EventService, UtilityService } from 'app/shared/services';
import { Errors } from 'app/shared/models';
import { ProfileService } from 'app/profile/_services/profile.service';
import { Subject } from 'rxjs';
import { environment, appVersion } from '../../environments/environment';
import { EmbedService } from 'app/embed/_services/embed.service';

@Component({
	selector: 'auth-page',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
	errors: Errors = new Errors();
	private ngUnsubscribe: Subject<any> = new Subject<any>();
	isSubmitting: boolean = false;
	// show: boolean = true;
	authForm: FormGroup;
	passwordResetForm: FormGroup;
	resetForm: boolean = false;
	loginForm: boolean = true;
	loginbg: string = "url(" + environment.asset_url + "/landing-page/1.jpeg)";
	blnValidPassword: boolean = false;
	newPassword: string;
	passwordVisible = false;
	version = appVersion;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private userService: UserService,
		private fb: FormBuilder,
		private eventService: EventService,
		private embedService: EmbedService,
		private utilityService: UtilityService,
		private profileService: ProfileService
	) {
		this.authForm = this.fb.group({
			'username': ['', Validators.required],
			'password': ['', Validators.required],

		});
		this.passwordResetForm = this.fb.group({
			'username': ['', Validators.required],
			// 'oldPassword': ['', Validators.required],
			'oldPassword': '',
			// 'newPassword': ['', Validators.required],
			'newPassword': '',
			'confirmPassword': ['', Validators.required],
			'userId': ''
		});
		this.loginbg = "url(" + environment.asset_url + "/landing-page/" + this.getRandomInt(1, 16) + ".jpeg)";
		this.userService.emitChange({ appName: 'Trendz V4' });
	}

	submitForm() {
		this.isSubmitting = true;
		this.errors = new Errors();
		this.userService
			.attemptAuthentication(this.authForm.value)
			.subscribe(
			data => {
				if (data['user'].resetToken) {
					this.passwordResetForm.controls.username.setValue((this.authForm.controls.username.value).toUpperCase());
					this.passwordResetForm.controls.userId.setValue(data['user'].userId);
					this.passwordResetForm.controls.newPassword.valueChanges
						.subscribe(
						data => {
							this.newPassword = data;
						}
						)
					this.resetForm = true;
					this.loginForm = false;
					this.isSubmitting = false;
				} else {
					this.eventService.isLoadingPage.next(true);
					this.eventService.onLogin.next(true);
					this.utilityService.fetchMenuDataFromDB().then(res => {
						this.utilityService.fetchBookmarksFromDB().then(res => {
							this.redirectToSavedUrl();
						});
					}, () => {
						this.utilityService.showAlerts('Failed to fetch menu items', true)
					});
				}
			},
			err => {
				console.log(err)
				// tslint:disable-next-line:quotemark
				this.utilityService.showAlerts(err.check ? err.check : "Fixing some ugly emergency server issues. We'll be back asap :(", true);
				this.errors = err;
				this.isSubmitting = false;
			}
			);
	}

	submitReset() {
		this.utilityService.validateForm(this.passwordResetForm);
		if (this.passwordResetForm.valid) {
			this.profileService.changePassword(this.passwordResetForm.value).pipe(
				catchError((err) => {
					return err;
				}),
				takeUntil(this.ngUnsubscribe),)
				.subscribe(data => {
					if (data.status === "S") {
						this.authForm.controls.username.setValue("");
						this.authForm.controls.password.setValue("");
						this.resetForm = false;
						this.loginForm = true;
						this.utilityService.showAlerts("Your password has been successfully changed.Login with  new password ", true);
					}
					else {
						this.utilityService.showAlerts(data.message, true);
					}
				});

		}
	}

	getRandomInt(min, max) {
		return Math.floor((Math.random() * max) + min);
	}

	validatePassword() {
		if (this.passwordResetForm.controls.newPassword.value == '')
			this.passwordResetForm.controls.newPassword.setErrors({ required: true });
		else {
			if (!this.blnValidPassword) {
				this.passwordResetForm.controls.newPassword.setErrors(null);
				this.passwordResetForm.controls.newPassword.setErrors({ pattern: true });
			}
			else {
				this.passwordResetForm.controls.newPassword.setErrors(null);
			}
		}
	}

	oldPwd() {
		if (this.passwordResetForm.controls.oldPassword.value == '')
			this.passwordResetForm.controls.oldPassword.setErrors({ required: true });
		else
			this.passwordResetForm.controls.oldPassword.setErrors(null);
	}

	checkConfirmPassword() {
		if (this.passwordResetForm.value.newPassword != this.passwordResetForm.value.confirmPassword) {
			this.passwordResetForm.controls.confirmPassword.setErrors({ unique: true });
		}

	}
	getPasswordValidity(event) {
		this.blnValidPassword = event;
	}

	redirectToSavedUrl() {
		if (this.userService.redirectUrl && this.utilityService.isValidUrl(this.userService.redirectUrl)
			&& this.userService.redirectUrl !== '/login' && this.userService.redirectUrl !== '/not-found') {
			if (this.embedService.isEmbedded) {
				let urlStr = this.userService.redirectUrl;
				this.userService.redirectUrl = urlStr.substr(0, urlStr.indexOf('token=') + 6) + this.userService.getCurrentUser().token;
				let message: any = {};
				message.action = "set-v4-token";
				message.token = this.userService.getCurrentUser().token;
				this.embedService.sendMessage(message);
			}
			this.utilityService.allowAlerts();
			this.router.navigateByUrl(this.userService.redirectUrl).then(done => {
				this.eventService.isLoadingPage.next(false);
			});
		} else {
			this.redirectToDashboard();
		}
	}

	redirectToMailBox() {
		this.utilityService.allowAlerts();
		this.router.navigateByUrl('/mailbox').then(done => {
			this.eventService.isLoadingPage.next(false);
		});
	}

	redirectToDashboard(){
		this.utilityService.fetchLoginIframeUrl().then(url => {
			if (url && url.iframeUrl && url.iframeUrl !== '') {
				this.utilityService.allowAlerts();
				this.userService.iframeData = url;
				this.router.navigateByUrl('').then(done => {
				this.userService.redirectUrl = '';
					this.eventService.isLoadingPage.next(false);
				});
			} else {
				this.redirectToMailBox()
			}
		})
		.catch( err => {
			this.redirectToMailBox();
		})
	}
}
