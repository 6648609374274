import { PageNotFoundComponent } from './shared/component/page-not-found/page-not-found.component';
import 'HammerJS';

import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { AdministrationComponent } from 'app/administration/administration.component';
import { PeoplezNavComponent } from 'app/app-nav';
import { BPMUtilsModule } from 'app/shared/bpmutils/bpmutils.module';
import { LoadingComponent, LOVComponent, NewlovComponent, LOVComponentV2 } from 'app/shared/component';
import { ClickOutsideDirective } from 'app/shared/directives/click-outside.directive';
import { FocusDirectiveDirective } from 'app/shared/directives/focus-directive.directive';
import { TrendzDateAdapter } from 'app/shared/models/trendz-date-adapter';
import { SchedulerUtilsModule } from 'app/shared/scheduler-utils/scheduler.utils.module';
import { TrendzDriveUserHomeComponent } from 'app/trendzdrive/drive-user-home/drive-user-home.component';
import { TrendzDriveModule } from 'app/trendzdrive/trendz-drive.module';
import { DndModule } from 'ng2-dnd';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { StorageServiceModule } from 'ngx-webstorage-service';

import { AppNavMenuComponent } from './app-nav/app-nav-menu/app-nav-menu.component';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HomeModule } from './home/home.module';
import {
	ApiService,
	FleetApiService,
	AuthGuard,
	EventService,
	HeaderLeftComponent,
	HeaderRightComponent,
	JwtService,
	SharedModule,
	SidenavComponent,
	TrendzErrorHandler,
	UserService,
	UtilityService,
	TrendzMessageService,
	LocalConfigService
} from './shared';
import { UserNotificationsComponent } from './gadgets/user-notifications/user-notifications.component';
import { UserDownloadsComponent } from './gadgets/user-downloads/user-downloads.component';
import { ProgramMoreInfoComponent } from './scheduler/program-more-info/more-info';
import { UserProfileMenuComponent } from './gadgets/user-profile-menu/user-profile-menu.component';
import { ApplicationReportListingComponent } from 'app/shared/scheduler-utils/app-report-listing/list.';
import { ApplicationProgramListingComponent } from 'app/shared/scheduler-utils/app-program-listing/list.';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { EmbedService } from './embed/_services/embed.service';
import { AuthPopUpComponent } from './auth-pop-up/auth-pop-up.component';
import { PushMessageService } from 'app/shared/websocket/push-message.service';
import { UserSettingsComponent } from 'app/gadgets/user-settings/user-settings.component';
import { EMailOutputComponent } from "app/scheduler/email-output/email-output";
import { AuthService } from 'app/shared/services/auth.service';
import { AcmApiService } from './shared/services/acm-api.service';
import { TrendzUploadComponent } from './trendz-upload/trendz-upload.component';

const TRENDZ_DATE_FORMATS = {
	parse: {
		dateInput: { month: 'long', year: 'numeric', day: 'numeric' },
	},
	display: {
		dateInput: 'input',
		monthYearLabel: { year: 'numeric', month: 'numeric' },
		dateA11yLabel: { year: 'numeric', month: 'numeric', day: 'numeric' },
		monthYearA11yLabel: { year: 'numeric', month: 'numeric' }
	},
};

const PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	//  suppressScrollX: true
};

const rootRouting: ModuleWithProviders = RouterModule.forRoot([
	{
		path: 'acm',
		loadChildren: 'app/acm/acm.module#AcmModule'
	},
	{
		path: 'dashboard',
		loadChildren: 'app/dashboard/dashboard.module#DashboardModule'
	},
	{
		path: 'embed',
		loadChildren: 'app/embed/embed.module#EmbedModule'
	},
	{
		path: 'mailbox',
		canActivate: [AuthGuard],
		loadChildren: 'app/mailbox/mailbox.module#MailboxModule'
	},
	{
		path: 'not-found',
		component: PageNotFoundComponent
	},
	{
		path: 'profile',
		canActivate: [AuthGuard],
		loadChildren: 'app/profile/profile.module#ProfileModule'
	},
	{
		path: 'scheduler',
		canActivate: [AuthGuard],
		loadChildren: 'app/scheduler/scheduler.module#SchedulerModule'
	},
	{
		path: 'hcm',
		canActivate: [AuthGuard],
		loadChildren: 'app/hcm/hcm.module#HcmModule'
	},
	{
		path: 'flm',
		canActivate: [AuthGuard],
		loadChildren: 'app/flm/flm.module#FlmModule'
	},
	{
		path: 'slf',
		canActivate: [AuthGuard],
		loadChildren: 'app/slf/slf.module#SlfModule'
	},
	{
		path: 'administration',
		canActivate: [AuthGuard],
		loadChildren: 'app/administration/administration.module#AdministrationModule'
	},
	{
		path: 'loading',
		canActivate: [AuthGuard],
		component: LoadingComponent
	},
	{
		path: 'trendz-drive',
		canActivate: [AuthGuard],
		component: TrendzDriveUserHomeComponent
	},
	{
		path: 'trendz-messages',
		canActivate: [AuthGuard],
		loadChildren: 'app/trendz-messages/trendz-messages.module#TrendzMessgesModule'
	},
	{
		path: 'trendz-upload',
		loadChildren: 'app/trendz-upload/trendz-upload.module#TrendzUploadModule'
	},
	{
		path: '**',
		canActivate: [AuthGuard],
		redirectTo: 'not-found'
	}
], { useHash: true });

@NgModule({
	declarations: [
		AppComponent,
		SidenavComponent,
		HeaderLeftComponent,
		HeaderRightComponent,
		PeoplezNavComponent,
		LOVComponent,
		LOVComponentV2,
		NewlovComponent,
		LoadingComponent,
		AdministrationComponent,
		AppNavMenuComponent,
		FocusDirectiveDirective,
		ClickOutsideDirective,
		PageNotFoundComponent,
		UserNotificationsComponent,
		UserDownloadsComponent,
		UserSettingsComponent,
		UserProfileMenuComponent,
		ProgramMoreInfoComponent,
		EMailOutputComponent,
		ApplicationReportListingComponent,
		ApplicationProgramListingComponent,
		AuthPopUpComponent
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		MatNativeDateModule,
		DndModule.forRoot(),
		PerfectScrollbarModule,
		StorageServiceModule,
		AuthModule,
		HomeModule,
		rootRouting,
		SharedModule,
		BPMUtilsModule,
		TrendzDriveModule,
		SchedulerUtilsModule,
		NgxDatatableModule
	],
	entryComponents: [
		LOVComponent,
		LOVComponentV2,
		NewlovComponent,
		ProgramMoreInfoComponent,
		EMailOutputComponent,
		AuthPopUpComponent
	],
	providers: [
		EmbedService,
		UtilityService,
		EventService,
		ApiService,
		AuthService,
		FleetApiService,
		AcmApiService,
		AuthGuard,
		JwtService,
		UserService,
		TrendzMessageService,
		MatNativeDateModule,
		PushMessageService,
		LocalConfigService,
		{ provide: DateAdapter, useClass: TrendzDateAdapter },
		{ provide: MAT_DATE_FORMATS, useValue: TRENDZ_DATE_FORMATS },
		{ provide: ErrorHandler, useClass: TrendzErrorHandler },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
