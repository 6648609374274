
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { ApiService, UtilityService } from "app/shared";
import { UserProfile, UsersListConfig } from "app/profile/_models";
import { Observable } from "rxjs";
import {HttpParams} from "@angular/common/http";
import { AuthService } from "../../shared/services/auth.service";



@Injectable()
export class ProfileService {
    constructor(
        private apiService: ApiService,
        private utilityService: UtilityService,
        private authService: AuthService
    ) { }

    getUserProfile(userId: any): Observable<{ user: UserProfile }> {
        return this.apiService.get('/trendz/users/' + userId);
    }

    saveUserProfile(userProfile: any): Observable<any> {
        return this.apiService.put('/trendz/users/' + userProfile.userId, { user: userProfile });
    }

    changePassword(userPassword: any): Observable<any> {
        const config = {
            token: 'resetToken'
        }
        return this.authService.put('/trendz/users/password/' + userPassword.userId, { user: userPassword }, config);
    }

    getAllUsers(config: UsersListConfig): Observable<{ count: number, users: any }> {
        // Convert any filters over to Angular's URLSearchParams
        let params: HttpParams = new HttpParams();
        Object.keys(config.config)
            .forEach((key) => {
                params = params.set(key, config.config[key]);
            });
        params = params.set("filters", config.getFilters());
        return this.apiService.get('/trendz/users/', params).pipe(map(data => data));

    }


}
