import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";

@Component({
	selector: 'app-administration',
	templateUrl: './administration.component.html'
})

export class AdministrationComponent implements OnInit {
	curExpand;
	curMenu;
	curSubExpand;
	constructor(private router: ActivatedRoute) { }

	ngOnInit() {
		this.curMenu = this.router.url;
	}
}
