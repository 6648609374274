import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { Subscription } from 'rxjs';

import { UserService, EventService } from 'app/shared/services';
import { User } from 'app/shared/models';

@Component({
	selector: 'trendz-drive-user-home',
	templateUrl: './drive-user-home.component.html'
})
export class TrendzDriveUserHomeComponent implements OnInit, OnDestroy {

	basePath;
	privilages = { view: 4, create: 4, edit: 4, delete: 4 };
	currentUser: User;
	userSubs: Subscription;

	constructor(
		private userService: UserService,
		private eventService: EventService,
	) { 
		this.eventService.applicationLoaded.next("trendz-drive");
	}

	ngOnInit() {
		this.userSubs = this.userService.currentUser.subscribe(
			(userData) => {
				this.currentUser = userData;
				this.basePath = "user-homes/" + this.currentUser.userName;
			}
		);
	}

	ngOnDestroy() {
		if (this.userSubs)
			this.userSubs.unsubscribe();
	}
}
