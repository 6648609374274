import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {HttpParams} from "@angular/common/http";
import { MatDialogRef, MatDialog } from '@angular/material';

import { UtilityService } from '../../services/utility.service';
import { ApiService } from './../../services/api.service';
import { SmdDataTable } from './../smd-datatable/datatable.component';
import { DatePipe } from '@angular/common';
import { environment } from 'environments/environment';
import { FleetApiService } from 'app/shared/services/fleet-api.service';
import { LoadingComponent } from '..';

@Component({
	selector: 'lov-component',
	templateUrl: './lov-component.html'
})
export class LOVComponent implements OnInit, OnDestroy {
	selectedRows: any;
	primaryKey;
	assetEnv = environment.asset_url;
	assetUrl = environment.asset_url;
	selectMultiple: boolean = false;
	width = 'input-card no-padding popup-width';
	paginated: boolean = false;
	rowCount;
	application = 'HCM';
	countRows: boolean = false;
	title = "LOV";
	apiClass;
	apiMethod = 'GET';
	postBody: any = {};
	limit = 30;
	apiUrl;
	dataHeader;
	fields: any[];
	listAttrs;
	listAttrTitles;
	sortable: boolean = false;
	filterAttrs;
	numericAttrs = [];
	dateFieldAttrs = [];
	timeFieldAttrs = [];
	sortAttrs = [];
	searchParams: HttpParams;
	returnAttrs;
	isTitleNeeded = true;
	numeric: Array<boolean> = [];
	dateField: Array<boolean> = [];
	private dataSubs;
	records;
	recordsAll;
	searchEnabled = true;
	filterValue;
	model;
	searchDB = true;
	private init = false;
	toolTipAttrs = [];
	iconAttrs = [];
	icons = [];
	iconColorAttrs = [];
	flagAttrs = [];
	genderAttrs = [];
	showTableFilter: boolean = true;
	showColumnFilter: boolean = false;
	existingattrs = [];
	uniqueKey;
	useInternalSearch = false;
	models = null;
	isToggle: boolean = false;
	toggleData: any = [];
	toggle: boolean = true;
  toggleApi: any = [];
  checkDuplicateInMultipleRows: Boolean = false;
  checkDuplicateKey: any;
  checkDuplicateErrorMessage: any;
  duplicateError: Boolean = false;
  duplicateErrorMessage: String = "";
	@ViewChild(SmdDataTable) dataTable: SmdDataTable;
	@ViewChild('searchInput') searchInput: ElementRef;

	constructor(
		public dialogRef: MatDialogRef<LOVComponent>,
		private apiService: ApiService,
		public fleetApiService: FleetApiService,
    private utilityService: UtilityService
	) { }

	ngOnInit() {
		if (!this.searchParams)
			this.searchParams = new HttpParams();
		if (!this.filterAttrs)
			this.filterAttrs = this.listAttrs;
		if (!this.listAttrTitles)
			this.listAttrTitles = this.listAttrs;
		if (!this.returnAttrs)
			this.returnAttrs = this.listAttrs;
		if (!this.apiClass)
			this.apiClass = this.apiService;
		if (!this.fields) {
			this.fields = [];
			if (this.listAttrs) {
				for (let i = 0; i < this.listAttrs.length; i++) {
					let field: any = {};
					field.key = this.listAttrs[i];
					field.title = this.listAttrTitles[i];
					field.toolTip = this.toolTipAttrs[i];
					field.iconImg = this.icons[i];
					field.iconColor = this.iconColorAttrs[i];
					field.filterable = this.filterAttrs.indexOf(field.key) > -1;
					field.sortable = this.numericAttrs.indexOf(field.key) > -1;
					field.dateField = this.dateFieldAttrs.indexOf(field.key) > -1;
					field.timeField = this.timeFieldAttrs.indexOf(field.key) > -1;
					field.numeric = this.sortAttrs.indexOf(field.key) > -1;
					field.flag = this.flagAttrs.indexOf(field.key) > -1;
					field.gender = this.genderAttrs.indexOf(field.key) > -1;
					field.icon = this.iconAttrs.indexOf(field.key) > -1;
					this.fields.push(field);
				}
			}
		}
	}

	ngOnDestroy() {
		if (this.dataSubs)
			this.dataSubs.unsubscribe();
		delete this.apiClass;
		delete this.apiUrl;
		delete this.dataHeader;
		delete this.listAttrs;
		delete this.listAttrTitles;
		delete this.filterAttrs;
		delete this.searchParams;
		delete this.returnAttrs;
		delete this.records;
	}

	rowSelected(event) {
    this.model = event.model;
    this.duplicateError = false;
		if (event.selected && !this.selectMultiple) {
			this.selectRecord();
    }
    if(this.checkDuplicateInMultipleRows)
      this.checkDuplicate(this.dataTable.selectedModels());
	}

	checkedRows(event) {
		if (event.checkedArr.checkedList) {
			this.selectedRows = event.checkedArr.checkedList;
    }
	}
	selectRecord() {
		if (this.selectMultiple) {
			if (this.primaryKey && this.primaryKey.length > 0 && this.selectedRows){
        if(this.checkDuplicateInMultipleRows){
          if(this.checkDuplicate(this.selectedRows))
            this.dialogRef.close(this.selectedRows);
        }
        else
				  this.dialogRef.close(this.selectedRows);
      }
			else{
        if(this.checkDuplicateInMultipleRows){
          if(this.checkDuplicate(this.dataTable.selectedModels()))
            this.dialogRef.close(this.dataTable.selectedModels());
        }
        else
				  this.dialogRef.close(this.dataTable.selectedModels());
      }
		}
		else {
			if (this.model) {
				this.dialogRef.close(this.model);
			} else {
				this.utilityService.showAlerts('Selection failed');
			}
		}
	}

	toLower(upper) {
			return upper ? upper.toLowerCase() : "";
	}
	convertToDate(date) {
		return this.formatToDisplayDate(!date || date == '' ? null : new Date(date).toString());
	}

	convertToDateTime(date) {
		return this.formatToDisplayTime(!date || date == '' ? null : new Date(date).toString());
	}

	formatToDisplayDate(date: string) {
		if (date) {
			let datePipe = new DatePipe("en-US");
			return datePipe.transform(date, 'dd-MMM-yyyy');
		}
		else return "";
	}
	formatToDisplayTime(date: string) {
		if (date) {
			let datePipe = new DatePipe("en-US");
			return datePipe.transform(date, 'dd-MMM-yyyy HH:mm');
		}
		else return "";
	}

	_onDataChange(dataChange: any) {
		if (this.existingattrs.length > 0 && this.uniqueKey) {

			let apiData = [];
			apiData = dataChange.data[this.dataHeader];
			for (let idx = 0; idx < apiData.length; idx++) {
				let index = this.existingattrs.indexOf(apiData[idx][this.uniqueKey]);
				if (index >= 0) {
					apiData.splice(idx, 1);
					idx--;
				}
			}
			this.dataTable.refresh(apiData);
		}

	}

	onToggleChange(event){
		if(this.toggle){
			this.apiUrl = this.toggleApi[0];
			this.dataTable.dataUrl = this.apiUrl;
			this.dataTable.refresh();
		}
		else{
			this.apiUrl = this.toggleApi[1];
			this.dataTable.dataUrl = this.apiUrl;
			this.dataTable.refresh();
		}
  }

  checkDuplicate(model){
    this.duplicateError = false;
    let selectedData = model;
    let duplicateRecordsSet = new Set();
    let message: String = "";
    let flag = selectedData.some(element=>{
      if(duplicateRecordsSet.size == duplicateRecordsSet.add(element[this.checkDuplicateKey]).size)
        message = message ? message + ", " : "" + element[this.checkDuplicateKey];
      return message != ""
    });
    if(flag){
      this.duplicateError = true;
      this.duplicateErrorMessage = this.checkDuplicateErrorMessage + ' (Personnel#: ' + message + ')';
    }
    return !flag;
  }

  onAllRowSelected(event){
    if(event){
      this.duplicateError = false;
      if(this.checkDuplicateInMultipleRows)
        this.checkDuplicate(this.dataTable.selectedModels());
    }
  }
}
