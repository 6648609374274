import { Injectable } from '@angular/core';
import { ListUsers, UsersListConfig, ListRoles } from "app/administration/trendz/trendz-users/_models";
import { Subject } from "rxjs";

@Injectable()
export class TrendzUsersSharedService {

    public users: ListUsers[];
    public userId;
    public roleUserAsignmntId;
    public count: number;
    public userRolecount: number;
    public userRoles: ListRoles[];
    public list: number[] = [];
    public roleList: number[] = [];
    public listConfig: UsersListConfig = new UsersListConfig();
    public columnFilterValues;

    private emitChangeSource = new Subject<any>();
    changeEmitted$ = this.emitChangeSource.asObservable();
    emitChange(change: any) {
        this.emitChangeSource.next(change);
    }

    setUsersData(data) {
        this.users = data.users;
        this.count = data.count;
        this.list = [];
        for (const key in this.users) {
            this.list.push(this.users[key].userId);
        }
        this.emitChange({ totalUsers: data.count });
    }

    setRoles(data) {
        this.userRoles = data.userRoles;
        this.userRolecount = data.count;
        this.roleList = [];
        for (const key in this.userRoles) {
            this.roleList.push(this.userRoles[key].roleUserAsignmntId);
        }
        this.emitChange({ totalRoles: data.count });
    }

    init() {
        this.list = [];
        this.roleList = [];
        this.listConfig = new UsersListConfig();
    }

    clear() {
        delete this.users;
        delete this.count;
        delete this.list;
        delete this.roleList;
        delete this.userRoles;
        delete this.listConfig;
    }
}
