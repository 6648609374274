import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import {
    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatRippleModule,
    MatStepperModule,
    MatListModule,
    MatGridListModule,
    MatProgressBarModule,
    MatProgressSpinnerModule
} from '@angular/material';
import { RouterModule } from '@angular/router';
import { DriveButtonDirective } from 'app/trendzdrive/utils';
import { ImageCropperModule } from 'ng2-img-cropper';

import {
    ConfirmPopupComponent,
    ExpansionPanelButtons,
    ExpansionPanelComponent,
    ExpansionPanelContent,
    ExpansionPanelDescriptionHidden,
    ExpansionPanelDescriptionToggled,
    ExpansionPanelsContainerComponent,
    ExpansionPanelTitle,
    NoDataToDisplayComponent,
    PeoplezFormComponent,
    PersonProfileCommonComponent,
    SmdBottomNavComponent,
    SmdBottomNavGroupComponent,
    SmdBottomNavLabelDirective,
    SmdContextualDatatableButton,
    SmdDataTable,
    SmdDatatableActionButton,
    SmdDataTableCellComponent,
    SmdDataTableColumnComponent,
    SmdDataTableRowComponent,
    SmdErrorMessageComponent,
    SmdErrorMessagesComponent,
    SmdFabSpeedDialActions,
    SmdFabSpeedDialComponent,
    SmdFabSpeedDialTrigger,
    SmdPaginatorComponent,
    UploadFileComponent,
} from './component';
import { CropImageComponent } from './component/crop-image/crop-image.component';
import { AutosizeTextareaDirective } from './directives/autosize-textarea.directive';
import { ImageDirective } from './directives/image/image.directive';
import { ListErrorsComponent } from './list-errors.component';
import { ShowAuthedDirective } from './show-authed.directive';
import { ValidateUploadedComponent } from 'app/shared/component/validate-uploaded-list/validate-uploaded.component';
import { CommentsComponent } from './component/comments/comments.component';
import { CommentDirective } from './directives/comments/comment.directive';
import { TrendzPipesModule } from "./pipes/pipes.module";
import { PasswordCheckerComponent } from './component/password-checker/password-checker.component';
import { SchedulerJobStatePipe } from 'app/scheduler/_services/jobStatePipe';
import { SchedulerJobStatusPipe } from 'app/scheduler/_services/jobStatusPipe';
import { LayoutModule } from '@angular/cdk/layout';
import { LoadingDirective } from 'app/shared/directives/loading/loading.directive';
import { CommentsModalComponent } from 'app/shared/component/comments/comments-modal/comments-modal.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { TrendzFilePreviewComponent } from 'app/shared/component/file-preview/file-preview.component';
import { VehicleDetailsComponent } from 'app/shared/component/vehicle-details/vehicle-details.component';
import { EmployeeUserLinkComponent } from './layout/employee-user-link/employee-user-link.component';
import { InputDateFilterDirective } from "app/shared/directives/date-format/date-format.directive";
import { QuickSaveDirective } from "app/shared/directives/quick-save/quick-save.directive";
import { TimeFormatDirective } from "app/shared/directives/time-format/time-format.directive";
import { InputCalculatorDirective } from "app/shared/directives/input-calculator/input-calculator.directive";
import { IframeComponent } from './iframe/iframe.component';
import { PageNotFoundComponent } from "app/shared/component/page-not-found/page-not-found.component";
const MATERIAL_MODULE = [
    MatAutocompleteModule
    , MatCheckboxModule
    , MatDatepickerModule
    , MatInputModule
    , MatSelectModule
    , MatSlideToggleModule
    , MatMenuModule
    , MatSidenavModule
    , MatToolbarModule
    , MatCardModule
    , MatTabsModule
    , MatExpansionModule
    , MatButtonModule
    , MatButtonToggleModule
    , MatChipsModule
    , MatIconModule
    , MatDialogModule
    , MatTooltipModule
    , MatSnackBarModule
    , MatRippleModule
    , MatRadioModule
    , MatStepperModule
    , MatListModule
    , MatGridListModule
    , MatProgressBarModule
    , MatProgressSpinnerModule
];
const COMPONENTS = [
    ListErrorsComponent,
    ShowAuthedDirective,
    SmdDataTable,
    SmdDatatableActionButton,
    SmdContextualDatatableButton,
    SmdDataTableColumnComponent,
    SmdDataTableRowComponent,
    SmdDataTableCellComponent,
    SmdPaginatorComponent,
    SmdFabSpeedDialTrigger,
    SmdFabSpeedDialActions,
    SmdFabSpeedDialComponent,
    SmdBottomNavLabelDirective,
    SmdBottomNavGroupComponent,
    SmdBottomNavComponent,
    SmdErrorMessageComponent,
    SmdErrorMessagesComponent,
    PeoplezFormComponent,
    ConfirmPopupComponent,
    ExpansionPanelsContainerComponent,
    ExpansionPanelComponent,
    ExpansionPanelTitle,
    ExpansionPanelContent,
    ExpansionPanelDescriptionToggled,
    ExpansionPanelDescriptionHidden,
    ExpansionPanelButtons,
    NoDataToDisplayComponent,
    PersonProfileCommonComponent,
    VehicleDetailsComponent,
    ImageDirective,
    CropImageComponent,
    UploadFileComponent,
    DriveButtonDirective,
    CommentDirective,
    ValidateUploadedComponent,
    PasswordCheckerComponent,
    CommentsModalComponent,
    CommentsComponent,
    PasswordCheckerComponent,
    TrendzFilePreviewComponent,
    IframeComponent,
];
const DIRECTIVES = [
    AutosizeTextareaDirective,
    LoadingDirective,
    InputDateFilterDirective,
    InputCalculatorDirective,
    QuickSaveDirective,
    TimeFormatDirective
];
const PIPES = [
    SchedulerJobStatePipe,
    SchedulerJobStatusPipe,
];

const IMPORTS = [
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    RouterModule,
    ImageCropperModule,
    FlexLayoutModule,
    TrendzPipesModule,
    PerfectScrollbarModule
];


@NgModule({
    imports: [IMPORTS, MATERIAL_MODULE, LayoutModule],
    declarations: [COMPONENTS, DIRECTIVES, PIPES, EmployeeUserLinkComponent],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        RouterModule,
        COMPONENTS,
        DIRECTIVES,
        PIPES,
        FlexLayoutModule,
        MATERIAL_MODULE,
        TrendzPipesModule,
        LayoutModule
    ],
    entryComponents: [
        ConfirmPopupComponent,
        CropImageComponent,
        UploadFileComponent,
        ValidateUploadedComponent,
        CommentsModalComponent,
        TrendzFilePreviewComponent,
        EmployeeUserLinkComponent
    ]
})
export class SharedModule {
    static forRoot(...imports: any[]): any[] {
        return [
            HttpClientModule,
            FormsModule,
            ReactiveFormsModule,
            CommonModule,
            MATERIAL_MODULE,
            RouterModule,
            SharedModule,
            ...imports
        ]
    }

}
