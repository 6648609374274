import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared';

import { BPMUtils } from './';
import { DocumentActionFormComponent } from './document-action-form/document-action-form';
import { BPMUtilsUserActions } from 'app/shared/bpmutils/bpmutils-user-actions.service';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

@NgModule({
	imports: [
		SharedModule,
		PerfectScrollbarModule
	],
	declarations: [
		DocumentActionFormComponent,
	],
	entryComponents: [
		DocumentActionFormComponent
	],
	providers: [
		BPMUtils,
		BPMUtilsUserActions
	]
})
export class BPMUtilsModule { }
