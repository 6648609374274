export interface ChoiceList {
    value: string;
    label: string;
}

export interface PersonsChoiceList {
    value3: string;
    value: number;
}

export interface RolesChoiceList {
    roleId: number;
    roleName: string;
    shortCode: string;
}

export class NewOrgAccess {
    division: ChoiceList;
    isAllowed: boolean;
    isDefault: boolean;
    role: ChoiceList;
    roleId: string;
    active: boolean;
    facility: ChoiceList;
    userId: string;
}


export class OrgAccess {
    division: ChoiceList;
    isAllowed: boolean;
    isDefault: boolean;
    role: ChoiceList;
    roleId: string;
    active: boolean;
    facility: ChoiceList;
    userId: string;
    orgAccessId: any;
}


export interface ListUsers {
    gender: ChoiceList;
    genderCode: string;
    passwordAccessesLeft: string;
    endDate: any;
    description: string;
    userName: string,
    userId: number;
    persons: PersonsChoiceList;
    emailAddress: string;
    phoneNumber: string;
    knownAs: string;
    hcmPersonId: number;
    fontWeight: string;
    countryCode: string;
    lastLogonDate: any;
    icon: string;
    startDate: any;
    value3: string;
    color: string;
    firstName: string;
    middleName: string;
    lastName: string;
    birthDate: any;
    status: string;
    personalTitle: ChoiceList;
    password: string;
    password2: string;
}


export interface ListOrgAccess {
    division: ChoiceList;
    isAllowed: boolean;
    isDefault: boolean;
    role: ChoiceList;
    orgAccessId: any;
    roleId: string;
    active: boolean;
    facility: ChoiceList;
    userId: string;
}


export interface ListRoles {
    endDate: any;
    roles: RolesChoiceList;
    userId: number;
    roleUserAsignmntId: number;
    startDate: any;
    icon: string;
    status: string;
    status2: string;
    color: string;
}


export class UsersListConfig {
    type: string = 'all';
    config: {
      limit?: number,
      offset?: number,
      sort?: string,
      direction?: string,
      asOfDate?: string
    } = {};
    filters: FilterAttribute[] = [ 
      {
        type: "item",
        con: "or", 
        attr: "personnelNumber",
        operator: "like",
        value: "",
      },
      {
        type: "item",
        con: "or",
        attr: "passwordAccessesLeft",
        operator: "like",
        value: "",
      },
      {
        type: "item",
        con: "or",
        attr: "endDate",
        operator: "like",
        value: "",
      },
      {
        type: "item",
        con: "or",
        attr: "description",
        operator: "like",
        value: "",
      } ,{
        type: "item",
        con: "or",
        attr: "username",
        operator: "like",
        value: "",
      }, {
        type: "item",
        con: "or",
        attr: "persons",
        operator: "like",
        value: "",
      }, {
        type: "item",
        con: "or",
        attr: "emailAddress",
        operator: "like",
        value: "",
      }, 
      {
        type: "item",
        con: "or",
        attr: "phoneNumber",
        operator: "like",
        value: "",
      },  
      {
        type: "item",
        con: "or",
        attr: "hcmPersonId",
        operator: "like",
        value: "",
      },
      {
        type: "item",
        con: "or",
        attr: "countryCode",
        operator: "like",
        value: "",
      },
      {
        type: "item",
        con: "or",
        attr: "lastLogonDate",
        operator: "like",
        value: "",
      }, 
      {
        type: "item",
        con: "or",
        attr: "startDate",
        operator: "like",
        value: "",
      }, 
      {
        type: "item",
        con: "or",
        attr: "remarks",
        operator: "like",
        value: "",
      }, 
    ];
  
    getFilters(): string {
      return JSON.stringify(this.filters);
    } 
}

export interface OrgPrefSettings {
    defaultDivision: ChoiceList;
    defaultFacility: boolean;
    division: ChoiceList;
    facility: ChoiceList;
    orgPrefId: string;
    active: boolean;
    userId: string;
    orgAccessId: any;
    taskFlow: ChoiceList;
}

export interface defaultDivisionChoiceList{
    defaultDivision: String;
    shortCode: String;
}

export interface defaultFacilityChoiceList {
    defaultFacility: String;
    shortCode: String;
}

export interface taskFlow{
    taskFlowId: String;
    taskFlowName: String;
} 


export interface FilterAttribute {
    type: string;
    con: string;
    attr: string;
    operator: string;
    value: string;
  }

